@-webkit-keyframes animateHeader {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes animateHeader {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@-webkit-keyframes animatePromoBg {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes animatePromoBg {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@-webkit-keyframes animNextBtn {
    0% {
        transform: translateX(-30%);
    }
    50% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(-30%);
    }
}
@keyframes animNextBtn {
    0% {
        transform: translateX(-30%);
    }
    50% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(-30%);
    }
}
@-webkit-keyframes animateFadeInBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes animateFadeInBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInRight {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes animateFadeInRight {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInRightHalf {
    0% {
        transform: translateX(30px);
        opacity: 0;
        pointer-events: none;
    }
    100% {
        transform: translateY(0);
        opacity: 0.5;
        pointer-events: unset;
    }
}
@keyframes animateFadeInRightHalf {
    0% {
        transform: translateX(30px);
        opacity: 0;
        pointer-events: none;
    }
    100% {
        transform: translateY(0);
        opacity: 0.5;
        pointer-events: unset;
    }
}
@-webkit-keyframes animateFadeInNextBtn {
    0% {
        transform: translate(-30%, 100%);
        opacity: 0;
    }
    100% {
        transform: translate(-30%, 0);
        opacity: 1;
    }
}
@keyframes animateFadeInNextBtn {
    0% {
        transform: translate(-30%, 100%);
        opacity: 0;
    }
    100% {
        transform: translate(-30%, 0);
        opacity: 1;
    }
}
@-webkit-keyframes animateOnlyOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes animateOnlyOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInImg {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes animateFadeInImg {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
body {
    margin: 0;
}
*,
::after,
::before {
    box-sizing: border-box;
}
* {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3e3e3e;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}
a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}
ol,
ul {
    list-style: none;
}
img {
    max-width: 100%;
}
address,
b,
blockquote,
button,
fieldset,
figure,
li,
ol,
p,
span,
time,
ul {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    font-style: inherit;
}
strong {
    font-weight: 700;
}
fieldset {
    border: none;
}
button {
    background-color: #fff;
    border: none;
    cursor: pointer;
}
time {
    display: inline-block;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none;
}
.sr-only {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
}
.preload {
    display: none;
}
.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #071b55;
    z-index: 10;
}
.overlay_inactive {
    display: none;
}
.main {
    padding-left: 280px;
}
.main__wrapper {
    display: none;
}
.delete-animation {
    -webkit-animation: none !important;
    animation: none !important;
    transform: translate(0, 0) !important;
    opacity: 1 !important;
}
.section {
    padding: 75px 200px 75px 100px;
    background-color: #fff;
}
.section-first {
    padding: 10px 200px 75px 100px;
}
.section-category {
    padding: 10px 200px 75px 100px;
}
.section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #11255f;
}
@media (max-width: 991px) {
    .swiper-slide--hide {
        display: none !important;
    }
}
@media (max-width: 1919px) {
    .section {
        padding: 75px 150px 75px 70px;
    }
    .section-category {
        padding: 10px 100px 75px 70px;
    }
    .section-first {
        padding: 10px 150px 75px 70px;
    }
}
@media (max-width: 1599px) {
    .section {
        padding: 60px 120px 50px 50px;
    }
    .section-category {
        padding: 10px 70px 50px 50px;
    }
    .section-first {
        padding: 10px 120px 50px 50px;
    }
}
@media (max-width: 1439px) {
    .main {
        padding-left: 250px;
    }
    .section {
        padding: 50px 100px 50px 30px;
    }
    .section-category {
        padding: 10px 50px 50px 30px;
    }
    .section-first {
        padding: 10px 100px 50px 30px;
    }
}
@media (max-width: 1199px) {
    .section {
        padding: 50px 50px 50px 30px;
    }
    .section-category {
        padding: 10px 50px 50px 30px;
    }
    .section-first {
        padding: 10px 50px 50px 30px;
    }
}
@media (max-width: 991px) {
    .overlay {
        display: none;
    }
    .main {
        padding-top: 0;
        padding-left: 0;
    }
    .main__swiper {
        display: none;
    }
    .main__wrapper {
        display: block;
    }
    .section {
        padding: 50px 30px 50px 30px;
    }
    .section-category {
        padding: 10px 30px 50px 30px;
    }
    .section-first {
        padding: 10px 30px 50px 30px;
    }
}
@media (max-width: 767px) {
    .section {
        padding: 50px 20px 50px 20px;
    }
    .section-category {
        padding: 10px 20px 50px 20px;
    }
    .section-first {
        padding: 10px 20px 50px 20px;
    }
}
@media (max-width: 575px) {
    .section {
        padding: 40px 10px 40px 10px;
    }
    .section-category {
        padding: 10px 10px 40px 10px;
    }
    .section-first {
        padding: 10px 10px 40px 10px;
    }
    .section-title_1920 {
        display: none;
    }
    .section-title_575 {
        display: block;
    }
}
.alert {
    position: fixed;
    top: 0;
    left: 0;
    padding: 9px;
    width: 100vw;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background-color: #e20000;
    z-index: 4;
}
@media (max-width: 991px) {
    .alert {
        position: static;
        padding-top: 99px;
    }
}
.preloader {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
}
.preloader_active {
    display: flex;
}
.preloader .swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 6;
}
.preloader .spinner {
    width: 130px;
    height: 130px;
    border: 6px solid #fff;
    border-radius: 50%;
    position: relative;
    -webkit-animation: preloaderanim 2s infinite linear;
    animation: preloaderanim 2s infinite linear;
    z-index: 7;
}
.preloader .spinner::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #11255f;
    position: absolute;
    top: 9.5px;
    left: 14px;
}
.preloader .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    z-index: 7;
}
@-webkit-keyframes preloaderanim {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes preloaderanim {
    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 1439px) {
    .spinner {
        width: 100px;
        height: 100px;
    }
    .spinner::before {
        top: 1.5px;
    }
}
@media (max-width: 991px) {
    .spinner {
        width: 70px;
        height: 70px;
    }
    .spinner::before {
        top: -4.5px;
    }
}
@media (max-width: 575px) {
    .spinner {
        width: 50px;
        height: 50px;
    }
    .spinner::before {
        top: -6.5px;
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 280px;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 2;
    transition: max-width 0s 0.4s;
}
.header__alive {
    opacity: 0;
    transition: 1s;
    position: absolute;
}
.header__alive1 {
    left: 88px;
}
.header__alive2 {
    left: 93px;
    top: 40px;
}
.header__alive3 {
    left: 105px;
    top: 42px;
}
.header__alive4 {
    left: 98px;
    top: 48px;
}
.header__alive5 {
    left: 104px;
    top: 40px;
}
.header__alive6 {
    left: 88px;
}
.header__alive7 {
    left: 92px;
    top: 40px;
}
.header__alive8 {
    left: 90px;
    top: 42px;
}
.header__alive9 {
    left: 90px;
    top: 42px;
}
.header__alive10 {
    left: 90px;
    top: 42px;
}
.header__alive11 {
    left: 90px;
    top: 42px;
}
.header__logo-blink {
    position: absolute;
    top: 50px;
    left: 250px;
    width: 1px;
    height: 1px;
    background: rgba(0, 0, 0, 0);
    transition: 1s;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 100px;
}
.header__logo-link:hover .header__alive1 {
    opacity: 1;
    transform: translate(76px, -21px);
}
.header__logo-link:hover .header__alive2 {
    transform: translate(51px, 2px);
    opacity: 1;
}
.header__logo-link:hover .header__alive3 {
    transform: translate(28px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive4 {
    transform: translate(80px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive5 {
    transform: translate(36px, -26px);
    opacity: 1;
}
.header__logo-link:hover .header__alive6 {
    transform: translate(68px, 0);
    opacity: 1;
}
.header__logo-link:hover .header__alive7 {
    transform: translate(96px, -33px);
    opacity: 1;
}
.header__logo-link:hover .header__alive8 {
    transform: translate(107px, -15px);
    opacity: 1;
}
.header__logo-link:hover .header__alive9 {
    transform: translate(29px, 2px);
    opacity: 1;
}
.header__logo-link:hover .header__alive10 {
    transform: translate(22px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive11 {
    transform: translate(26px, -22px);
    opacity: 1;
}
.header__logo-link:hover .header__logo-blink {
    box-shadow: -2px 2px 12px 6px #ff0;
}
.header__list-item--pic {
    width: 190px;
    display: flex;
    justify-content: space-between;
}
.header__list-item--pic a {
    transition: 0.5s;
}
.header__list-item--pic a:hover {
    transform: scale(1.15);
}
.header_991 {
    display: none;
}
.header_active {
    transition: max-width 0s 0s;
    max-width: 660px;
}
.header.animate {
    -webkit-animation: animateHeader 0.5s forwards;
    animation: animateHeader 0.5s forwards;
}
.header__container {
    max-width: 280px;
}
.header__logo {
    max-width: 200px;
    width: 100%;
    max-height: 56px;
    -o-object-fit: cover;
    object-fit: cover;
}
.header__logo-circle-gradient-start,
.header__logo-mask-gradient-start {
    transition: all 0.3s;
}
.header__logo-circle-gradient-finish,
.header__logo-mask-gradient-finish {
    transition: all 0.3s;
}
@media (min-width: 992px) {
    .header__logo:hover .header__logo-circle-gradient-start,
    .header__logo:hover .header__logo-mask-gradient-start {
        stop-color: #ffd300;
    }
    .header__logo:hover
        .header__logo-circle-gradient-finish,
    .header__logo:hover .header__logo-mask-gradient-finish {
        stop-color: #fd3256;
    }
}
.header__logo-wrapper {
    padding: 30px 20px 30px 60px;
    height: 12vh;
    height: calc(var(--vh, 1vh) * 12);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #11255f;
}
.header__logo-link {
    max-width: 100%;
    max-height: 100%;
}
.header__activity {
    display: inline-block;
    font-size: 13px;
    line-height: 19px;
    color: #fff;
}
.header__activity-wrapper {
    padding: 3px 20px 3px 60px;
    width: 100%;
    height: 4.8vh;
    background-color: #11255f;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header__info {
    padding: 30px 26px 40px 60px;
    height: 47.2vh;
    height: calc(var(--vh, 1vh) * 47.2);
    background-color: #11255f;
    overflow-y: hidden;
}
.header__info::-webkit-scrollbar {
    width: 5px;
}
.header__info::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}
.header__info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
.header__flex {
    display: flex;
    -moz-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
}
.header__catalogue-link {
    position: relative;
    padding: 16px 37px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid currentColor;
    overflow: hidden;
    transition: color 0.5s;
}
.header__catalogue-link::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .header__catalogue-link:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .header__catalogue-link:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.header__catalogue-link span {
    position: relative;
    z-index: 1;
}
.header__catalogue-link_menu {
    color: #11255f;
    background-color: #fff;
}
.header__catalogue-link_menu::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .header__catalogue-link_menu:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
}
.header__open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 39px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
@media (min-width: 992px) {
    .header__open:hover span:nth-child(2) {
        width: 100%;
    }
}
.header__open span {
    height: 3px;
    background-color: #fff;
}
.header__open span:nth-child(1) {
    width: 39px;
}
.header__open span:nth-child(2) {
    margin-top: 6px;
    max-width: 39px;
    width: 70%;
    transition: width 0.2s, opacity 0.2s;
}
.header__personal {
    display: none;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-top: 22px;
}
@media (min-width: 992px) {
    .header__personal-link:hover .header__personal-icon {
        transform: scale(1.1);
    }
    .header__personal-link:active .header__personal-icon {
        opacity: 0.5;
    }
}
.header__personal-link_cart {
    position: relative;
    margin-top: -1px;
}
.header__personal-icon {
    transition: transform 0.2s;
}
.header__nav {
    margin-top: 30px;
}
.header__list {
    row-gap: 20px;
}
.header__list-item:not(:first-child) {
    margin-top: 20px;
}
.header__list-link {
    color: #fff;
    position: relative;
}
.header__list-link::after,
.header__list-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.header__list-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.header__list-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .header__list-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .header__list-link:active {
        opacity: 0.5;
    }
}
@-webkit-keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.menu {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    max-width: 660px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fff;
    opacity: 0;
    transition: transform 0.5s, opacity 0.4s;
    z-index: 4;
}
.menu_active {
    transition: transform 0.5s, opacity 0.4s;
    transform: translateX(0);
    opacity: 1;
}
.menu__logo {
    max-width: 200px;
    width: 100%;
    max-height: 56px;
}
.menu__logo-wrapper {
    padding: 40px 60px 26px 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.menu__info {
    display: flex;
    flex-direction: column;
    padding: 36px 60px 40px 60px;
    height: calc(100vh - 199px);
    height: calc(var(--vh, 1vh) * 100 - 199px);
}
.menu__close {
    width: 40px;
    height: 40px;
    background: url(../../public/img/blocks/header/icons/menu-close.svg)
        center center/cover no-repeat;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.menu__nav {
    margin-top: 30px;
}
.menu__list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px;
}
.menu__left--start .menu__item a {
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.menu__list-name {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}
.menu__item-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
}
.menu__item-link::after,
.menu__item-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.menu__item-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.menu__item-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .menu__item-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .menu__item-link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.menu__item-link::after {
    background-color: rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
    .menu__item-link:hover::after {
        background-color: #3e3e3e;
    }
}
.menu__call {
    position: relative;
    margin-top: 72px;
    padding-left: 65px;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: #11255f;
}
.menu__call::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 35px;
    background: url(../../public/img/blocks/header/icons/call.svg)
        center center/cover no-repeat;
}
.menu__agatech-wrapper {
    display: flex;
    align-items: center;
    -moz-column-gap: 30px;
    column-gap: 30px;
    margin-top: auto;
}
.menu__agatech-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #11255f;
}
.menu__agatech-logo {
    max-width: 97px;
    width: 100%;
    max-height: 30px;
    -o-object-fit: cover;
    object-fit: cover;
}
@media (max-width: 1599px) {
    .header_active {
        max-width: 600px;
    }
    .header__activity-wrapper {
        display: none !important;
    }
    .header__logo-wrapper {
        padding-top: 15px;
        height: 10vh;
        height: calc(var(--vh, 1vh) * 10);
    }
    .header__info {
        height: 54vh;
        height: calc(var(--vh, 1vh) * 54);
    }
    .menu {
        max-width: 600px;
    }
    .menu__logo {
        max-width: 211px;
        max-height: 126px;
    }
    .menu__logo-wrapper {
        padding: 25px 40px 30px 40px;
    }
    .menu__info {
        padding: 40px;
        height: calc(100vh - 188px);
        height: calc(var(--vh, 1vh) * 100 - 188px);
    }
    .menu__nav {
        margin-top: 40px;
    }
    .menu__call {
        margin-top: 40px;
        padding-left: 40px;
        font-size: 26px;
        line-height: 30px;
    }
    .menu__call::before {
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 1439px) {
    .header {
        max-width: 250px;
    }
    .header_active {
        max-width: 500px;
    }
    .header__container {
        max-width: 250px;
    }
    .header__logo-wrapper {
        padding: 15px 55px 15px 30px;
        height: 10vh;
        height: calc(var(--vh, 1vh) * 10);
    }
    .header__activity-wrapper {
        padding: 3px 20px 3px 30px;
    }
    .header__info {
        padding: 30px 30px 35px 30px;
        height: 57vh;
        height: calc(var(--vh, 1vh) * 57);
    }
    .header__nav {
        margin-top: 15px;
    }
    .menu {
        max-width: 800px;
        width: 800px;
    }
    .menu__logo {
        max-width: 211px;
        max-height: 126px;
    }
    .menu__logo-wrapper {
        padding: 20px 30px 25px 30px;
    }
    .menu__info {
        padding: 40px 30px;
        height: calc(100vh - 178px);
        height: calc(var(--vh, 1vh) * 100 - 178px);
    }
    .menu__nav {
        margin-top: 40px;
    }
    .menu__item-link {
        font-size: 18px;
        line-height: 22px;
    }
    .menu__call {
        margin-top: 40px;
        padding-left: 40px;
        font-size: 18px;
        line-height: 25px;
    }
    .menu__call::before {
        width: 25px;
        height: 25px;
    }
}
@media (max-width: 991px) {
    .header {
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 7px 20px;
        max-width: unset;
        height: 90px;
        max-height: unset;
        background-color: #11255f;
        z-index: 3;
    }
    .header .menu__list {
        gap: 10px;
    }
    .header .menu__list .menu__item-link {
        font-size: 14px;
        margin-bottom: 0;
    }
    .header_1920 {
        display: none;
    }
    .header_991 {
        display: flex;
    }
    .header__logo-link {
        margin-left: 29px;
    }
    .header__personal {
        margin-top: 0;
    }
    .header__flex {
        -moz-column-gap: 50px;
        column-gap: 50px;
    }
    .menu {
        max-width: unset;
        width: 100%;
        position: absolute;
        transform: translateX(0);
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
    }
    .menu_active {
        transition: opacity 0.2s;
        transform: translateX(0);
        opacity: 1;
        pointer-events: unset;
    }
    .menu__info {
        padding: 20px;
    }
    .menu__close {
        width: 35px;
        height: 35px;
    }
    .menu__logo {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }
    .menu__logo-wrapper {
        display: grid;
        grid-template-columns: minmax(auto, 15%) minmax(
                auto,
                70%
            ) minmax(auto, 15%);
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 7px 20px;
        height: 90px;
    }
    .menu__logo-link {
        justify-self: center;
        max-width: 123.65px;
        max-height: 79.16px;
    }
    .menu__call {
        margin-top: 0;
        padding-left: 30px;
        font-size: 16px;
        line-height: 22px;
    }
    .menu__call_991 {
        display: inline-block;
    }
    .menu__call_575 {
        display: none;
    }
    .menu__call::before {
        width: 20px;
        height: 20px;
    }
    .menu__nav {
        margin-top: 30px;
    }
    .menu__item-link {
        font-size: 16px;
        line-height: 19px;
    }
}
@media (max-width: 575px) {
    .header {
        padding: 10px;
    }
    .menu__logo-wrapper {
        padding: 10px;
    }
    .menu__info {
        padding: 10px;
        max-height: calc(100vh - 90px);
        max-height: calc(var(--vh, 1vh) * 100 - 90px);
        height: 100%;
        overflow-y: auto;
    }
    .menu__info::-webkit-scrollbar {
        width: 5px;
    }
    .menu__info::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
    }
    .menu__info::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
    }
    .menu__nav {
        margin-top: 20px;
    }
    .menu__call {
        margin-top: 35px;
    }
    .menu__call_991 {
        display: none;
    }
    .menu__call_575 {
        display: inline-block;
    }
}
.callback {
    padding: 25px 20px 25px 60px;
    width: 100%;
    height: 36vh;
    height: calc(var(--vh, 1vh) * 36);
    background-color: #071b55;
    overflow-y: auto;
}
.callback::-webkit-scrollbar {
    width: 5px;
}
.callback::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}
.callback::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
.callback__wrapper {
    height: 154px;
}
.callback__title {
    text-transform: uppercase;
    color: #fff;
}
.callback__title_inactive {
    display: none;
}
.callback__form {
    margin-top: 15px;
}
.callback__form_inactive {
    display: none;
}
.callback__form-container {
    position: relative;
}
.callback__form-input {
    padding: 10px 30px 10px 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid #fff;
}
.callback__form-input::-moz-placeholder {
    color: #a9a9a9;
}
.callback__form-input::placeholder {
    color: #a9a9a9;
}
.callback__form-input:focus {
    width: calc(100% - 20px);
    margin-left: -10px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 40px;
    outline: 0;
    border: 1px solid #fff;
    border-radius: 5px;
}
.callback__form-submit {
    position: absolute;
    top: 13px;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.callback__form-arrow path {
    transition: fill 0.2s;
    fill: rgba(255, 255, 255, 0.5);
}
@media (min-width: 992px) {
    .callback__form-arrow:hover path {
        fill: #fff;
    }
}
.callback__form-label {
    display: flex;
    cursor: pointer;
    transition: opacity 0.1s;
}
@media (min-width: 992px) {
    .callback__form-label:active {
        opacity: 0.5;
    }
}
.callback__form-checkbox-real:checked
    + .callback__form-checkbox-fake {
    border: 1px solid #fff;
}
.callback__form-checkbox-real:checked
    + .callback__form-checkbox-fake::before {
    content: "";
    position: absolute;
    top: 48.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 10px;
    background: url(../../public/img/blocks/callback/icons/check.svg)
        center center/cover no-repeat;
}
.callback__form-checkbox-fake {
    position: relative;
    margin-top: 13px;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #a9a9a9;
}
.callback__form-checkbox-text {
    margin: 10px 0 0 7px;
    max-width: 89%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #a9a9a9;
}
.callback--vk:hover path {
    fill: #07f;
    stroke: #07f;
}
.callback--viber:hover path {
    fill: #7360f2;
    stroke: #7360f2;
}
.callback--wa:hover path {
    fill: #25d366;
    stroke: #25d366;
}
.callback--tg:hover path {
    fill: #08c;
    stroke: #08c;
}
.callback__social {
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-top: 5px;
}
.callback__social-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 992px) {
    .callback__social-link:hover {
        transform: scale(1.05);
    }
    .callback__social-link:hover .callback__social-icon {
        transform: scale(1.05);
    }
    .callback__social-link:active .callback__social-icon {
        opacity: 0.5;
    }
}
.callback__social-icon {
    transition: transform 0.2s;
}
.callback__message {
    display: none;
    text-transform: uppercase;
    color: #fff;
}
.callback__message_active {
    display: inline-block;
}
.callback__contacts {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 10px;
    margin-top: 10px;
}
.callback__mail,
.callback__phone {
    color: #fff;
    position: relative;
}
.callback__mail::after,
.callback__mail::before,
.callback__phone::after,
.callback__phone::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.callback__mail::before,
.callback__phone::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.callback__mail::after,
.callback__phone::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .callback__mail:hover::after,
    .callback__phone:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .callback__mail:active,
    .callback__phone:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.callback__privacy {
    display: inline;
    font-size: inherit;
    line-height: inherit;
}
@media (min-width: 992px) {
    .callback__privacy:hover {
        text-decoration: underline;
    }
    .callback__privacy:active {
        opacity: 0.5;
    }
}
@media (max-width: 1439px) {
    .callback {
        padding: 15px 20px 15px 30px;
        height: 37vh;
        height: calc(var(--vh, 1vh) * 37);
    }
    .callback__wrapper {
        height: auto;
    }
    .callback__title {
        display: none;
    }
    .callback__form {
        margin-top: 0;
    }
    .callback__social {
        margin-top: 15px;
    }
    .callback__mail {
        display: none;
    }
}
@media (max-width: 991px) {
    .callback__contacts {
        display: none;
    }
}
.spinner {
    width: 50px;
    height: 50px;
    border: 8px solid #fff;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spinneranim 2s infinite linear;
    animation: spinneranim 2s infinite linear;
}
.spinner-wrapper {
    display: none;
}
.spinner-wrapper_active {
    display: block;
}
.spinner::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #11255f;
    position: absolute;
    top: -7px;
    left: 14px;
}
@-webkit-keyframes spinneranim {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spinneranim {
    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 1439px) {
    .spinner {
        width: 40px;
        height: 40px;
        border: 6px solid #fff;
    }
    .spinner::before {
        top: -6px;
    }
}
.footer {
    display: none;
}
@media (max-width: 991px) {
    .footer {
        display: flex;
    }
    .footer__info {
        padding: 50px 20px 25px 40px;
        width: 100%;
        background-color: #11255f;
    }
    .footer__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        row-gap: 10px;
    }
    .footer__list-link {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
    }
    .footer__call {
        position: relative;
        margin-top: 20px;
        padding-left: 52px;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        color: #fff;
    }
    .footer__call::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 26px;
        height: 26px;
        background: url(../../public/img/blocks/footer/icons/call.svg)
            center center/cover no-repeat;
    }
    .footer__agatech-wrapper {
        display: flex;
        align-items: center;
        -moz-column-gap: 38px;
        column-gap: 38px;
        margin-top: 50px;
    }
    .footer__agatech-text {
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #a9a9a9;
    }
    .footer__agatech-logo {
        max-width: 97px;
        width: 100%;
        max-height: 30px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .callback {
        padding: 50px 20px;
        max-width: 260px;
        height: auto;
    }
}
@media (max-width: 767px) {
    .footer {
        flex-wrap: wrap;
    }
    .footer__info {
        padding: 40px 20px 40px 20px;
    }
    .footer__call {
        margin-top: 50px;
    }
    .callback {
        padding: 40px 20px;
        max-width: unset;
    }
    .callback__form {
        max-width: 300px;
    }
}
@media (max-width: 575px) {
    .footer__info {
        padding: 40px 10px 20px 10px;
    }
    .footer__list {
        grid-template-columns: repeat(2, auto);
    }
    .footer__agatech-wrapper {
        justify-content: space-between;
        -moz-column-gap: 0;
        column-gap: 0;
    }
    .callback {
        padding: 25px 10px;
    }
}
.slide-btn {
    position: absolute;
    bottom: 50px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
}
.slide-btn::after {
    content: "";
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    width: 27px;
    height: 10px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
}
.slide-btn_blue {
    color: #11255f;
}
.slide-btn_blue::after {
    background-color: #11255f;
}
.slide-btn_prev {
    left: 110px;
}
.slide-btn_prev::after {
    left: -50px;
    background-image: url(../../public/img/blocks/slider-buttons/arrow-prev.svg);
}
.slide-btn_prev-blue {
    color: #11255f;
}
.slide-btn_prev-blue::after {
    background-image: url(../../public/img/blocks/slider-buttons/arrow-prev-blue.svg);
}
.slide-btn_next {
    right: 105px;
}
.slide-btn_next::after {
    right: -50px;
    background-image: url(../../public/img/blocks/slider-buttons/arrow-next.svg);
}
.slide-btn_next-blue {
    color: #11255f;
}
.slide-btn_next-blue::after {
    background-image: url(../../public/img/blocks/slider-buttons/arrow-next-blue.svg);
}
@media (max-width: 1919px) {
    .slide-btn {
        bottom: 20px;
    }
}
@media (max-width: 1599px) {
    .slide-btn_prev {
        left: 65px;
    }
    .slide-btn_next {
        right: 90px;
    }
}
@media (max-width: 1439px) {
    .slide-btn {
        display: none;
    }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    transition-duration: 0.4s;
    pointer-events: none;
}
.modal_active {
    opacity: 1;
    pointer-events: unset;
}
.modal_active .modal__container {
    transform: translateY(0);
    opacity: 1;
}
.modal__container {
    position: relative;
    transform: translateY(-500px);
    max-width: 900px;
    width: 100%;
    min-height: 600px;
    max-height: 80vh;
    opacity: 0;
    transition-duration: 0.4s;
}
.modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.modal__message,
.modal__text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}
.modal__message {
    display: none;
    text-align: center;
}
.modal__message_active {
    display: block;
    transform: translateX(130px);
}
.modal__form_inactive {
    display: none;
}
.modal__form-input {
    padding: 13px 15px;
    width: 100%;
    color: #fff;
    background-color: #11255f;
    border: 1px solid #fff;
}
.modal__form-input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
}
.modal__form-input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}
.modal__form-submit {
    display: block;
    margin-top: 20px;
    width: 380px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #11255f;
}
.modal__user {
    display: flex;
    justify-content: space-between;
    -moz-column-gap: 20px;
    column-gap: 20px;
}
.modal__user-name,
.modal__user-phone,
.modal__user-surname {
    max-width: 50%;
    width: 100%;
}
.modal__theme {
    margin-top: 20px;
}
.modal__textarea {
    margin-top: 20px;
    min-height: 175px;
    max-height: 350px;
    resize: vertical;
}
.more__modal .modal__dev {
    padding: 17px 45px 17px 17px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
}
.more__modal .modal__close {
    color: #11255f;
}
.more__modal .modal__info {
    padding: 50px 40px;
    width: 100%;
    min-height: 530px;
    background-color: #11255f;
    overflow: auto;
}
.more__modal .modal__info-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}
.more__modal .modal__list {
    margin-top: 20px;
}
.more__modal .modal__item-text {
    color: #fff;
}
.more__modal .modal__item-link {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    position: relative;
}
.more__modal .modal__item-link::after,
.more__modal .modal__item-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.more__modal .modal__item-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.more__modal .modal__item-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .more__modal .modal__item-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .more__modal .modal__item-link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.more__modal .modal__contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 40px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.more__modal .modal__contacts-item {
    padding-left: 10px;
    border-left: 1px solid #fff;
}
.more__modal .modal__contacts-text {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
}
.review__modal .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 50px 69px 50px;
    background-color: #11255f;
}
.review__modal .modal__close {
    color: #fff;
}
.review__modal .modal__text {
    align-self: flex-start;
}
.review__modal .modal__text_inactive {
    display: none;
}
.review__modal .modal__form {
    margin-top: 30px;
    width: 100%;
}
.form__modal .modal__form #inputOutput {
    position: absolute;
    right: 0;
    top: 0;
    padding: 50px 10px 50px 10px;
    min-width: 300px;
    background: #071b55;
    height: 100%;
}
.form__modal .modal__form #inputOutput .inputOutput__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #fff;
    text-align: center;
}
.form__modal .modal__form #inputOutput .inputOutput__img {
    margin-bottom: 20px;
}
.form__modal .modal__form #inputOutput .dateOutput {
    margin-bottom: 10px;
}
.form__modal
    .modal__form
    #inputOutput
    .inputOutput__wrapper {
    margin-bottom: 10px;
    color: #fff;
}
.form__modal
    .modal__form
    #inputOutput
    .inputOutput__wrapper
    p {
    margin-bottom: 15px;
}
.form__modal .modal__form #inputOutput .checkboxOutput {
    display: none;
    position: relative;
    padding-left: 30px;
}
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
}
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--bengaL.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--colored.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--firE.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--pyro.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--salyT.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--smokE.visible {
    display: block;
    color: #fff;
}
.form__modal .modal__form .modal__form-input:focus-visible {
    outline: 0;
    border: 1px solid #fff;
}
.form__modal .modal__form .modal__form-input {
    border-bottom: 1px solid #fff;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
}
.form__modal .modal__form .modal__contact-info {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.form__modal .modal__form .modal__contact-info-left {
    flex-basis: 35%;
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-right {
    flex-basis: 50%;
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-address,
.form__modal .modal__form .modal__contact-info-mail,
.form__modal .modal__form .modal__contact-info-tel {
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-tel--mb {
    margin-bottom: 20px;
}
.form__modal .modal__form .modal__contact-info-address {
    white-space: nowrap;
    margin-bottom: 15px;
}
.form__modal .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 50px 20px 55px;
    min-height: 650px;
    background: url(../../public/img/blocks/modal/bg.jpg);
    min-width: 1000px;
    padding-right: 315px;
}
.form__modal .modal__close {
    color: #fff;
    z-index: 5;
}
.form__modal .modal__form {
    max-width: 100%;
    height: 540px;
}
.form__modal .modal__date {
    margin: 0;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
    font-size: 47px;
    line-height: 55px;
}
.form__modal .modal__date:focus-visible {
    outline: 0;
    border: none;
}
.form__modal .modal__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.form__modal .modal__step {
    color: #fff;
}
.form__modal .modal__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 40px;
    margin-top: 40px;
}
.form__modal .modal__item {
    cursor: pointer;
}
.form__modal .modal__item-picture {
    display: flex;
}
.form__modal .modal__item-img {
    max-width: 200px;
    width: 100%;
    max-height: 130px;
    -o-object-fit: cover;
    object-fit: cover;
}
.form__modal .modal__item-wrapper {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 4px 10px;
    background-color: rgba(255, 255, 255, 0.5);
}
.form__modal .modal__item-title {
    font-weight: 400;
    color: #fff;
}
.form__modal
    .modal__checkbox-real:checked
    + .modal__checkbox-fake {
    background-color: #fff;
}
.form__modal .modal__checkbox-fake {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
}
.form__modal .modal__user-name {
    margin-top: 40px;
}
.form__modal .modal__user-phone {
    margin-top: 20px;
}
.form__modal .swiper {
    height: 100%;
}
.form__modal .swiper-container {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.form__modal .swiper-container .modal__output {
    min-width: 240px;
    padding: 20px;
    color: #fff;
}
.form__modal .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.form__modal .modal__submit,
.form__modal .swiper-button-back,
.form__modal .swiper-button-next {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #fff;
    overflow: hidden;
    transition: color 0.5s;
    top: unset;
    left: unset;
    right: unset;
    margin: 0;
    padding: 16px 20px;
    width: auto;
    height: auto;
    opacity: 0.35;
    cursor: not-allowed;
    pointer-events: none;
}
.form__modal .modal__submit::before,
.form__modal .swiper-button-back::before,
.form__modal .swiper-button-next::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover,
    .form__modal .swiper-button-back:hover,
    .form__modal .swiper-button-next:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .form__modal .modal__submit:hover::before,
    .form__modal .swiper-button-back:hover::before,
    .form__modal .swiper-button-next:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.form__modal .modal__submit span,
.form__modal .swiper-button-back span,
.form__modal .swiper-button-next span {
    position: relative;
    z-index: 1;
}
.form__modal .modal__submit::before,
.form__modal .swiper-button-back::before,
.form__modal .swiper-button-next::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover,
    .form__modal .swiper-button-back:hover,
    .form__modal .swiper-button-next:hover {
        color: #fff;
        border: 1px solid #fff;
    }
}
.form__modal .modal__submit_enabled,
.form__modal .swiper-button-back_enabled,
.form__modal .swiper-button-next_enabled {
    opacity: 1 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover::after,
    .form__modal .swiper-button-back:hover::after,
    .form__modal .swiper-button-next:hover::after {
        color: #fff;
    }
}
.form__modal .modal__submit::after,
.form__modal .swiper-button-back::after,
.form__modal .swiper-button-next::after {
    padding-left: 20px;
    font-size: 14px;
    z-index: 1;
}
::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(../../public/img/blocks/modal/date.png)
        no-repeat center;
    background-size: contain;
}
#ui-datepicker-div {
    z-index: 10 !important;
}
input[type="date"] {
    position: relative;
    padding: 4px 0 0 25px !important;
    outline: 0;
    width: 62%;
    margin-top: 0 !important;
    background: rgba(0, 0, 0, 0);
    border: rgba(0, 0, 0, 0);
    color: #fff;
    font-size: 47px;
    box-sizing: border-box;
    line-height: 55px;
    text-align: center;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}
input[type="date"]::-webkit-clear-button {
    z-index: -10;
}
input[type="date"]::-webkit-input-placeholder {
    z-index: 99;
    white-space: nowrap;
}
input[type="date"]:not(.has-value):before {
    content: attr(placeholder);
    width: 100%;
}
input[type="date"] {
    padding: 12px 0;
}
.datetime-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.datetime-wrapper img {
    top: 50%;
    left: 95px;
    position: absolute;
    transform: translateY(-50%);
    width: 47px;
    height: 47px;
}
@media (max-width: 575px) {
    input[type="date"] {
        font-size: 24px !important;
    }
    .datetime-wrapper img {
        left: 45px;
    }
}
@media (max-width: 991px) {
    .modal__container {
        max-width: 90vw;
    }
    .review__modal .modal__container {
        padding: 70px 30px 50px 30px;
    }
    .review__modal .modal__form-submit {
        width: 100%;
    }
    .form__modal .modal__list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .modal__container {
        max-width: 100vw;
        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    .more__modal .modal__info {
        height: 100%;
    }
    .more__modal .modal__contacts {
        grid-template-columns: repeat(2, 1fr);
    }
    .review__modal .modal__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form__modal #inputOutput {
        display: none;
    }
    .form__modal .modal__contact-info {
        display: none !important;
    }
    .form__modal .modal__user-phone + .modal__text {
        display: none;
    }
    .form__modal .modal__message_active {
        transform: translateX(0);
    }
    .form__modal .modal__item-wrapper {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 5px;
    }
    .form__modal .modal__date {
        line-height: 24px;
        font-size: 24px;
    }
    .form__modal .modal__container {
        min-width: 320px;
        max-width: 320px;
        padding: 20px;
    }
    .form__modal .modal__form {
        height: 470px;
    }
    .form__modal .modal__item-wrapper {
        padding: 4px 9px;
    }
    .form__modal .modal__item-title {
        font-size: 12px;
        line-height: 20px;
    }
    .form__modal .modal__user-name,
    .form__modal .modal__user-phone {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .more__modal .modal__dev {
        font-size: 18px;
        line-height: 27px;
    }
    .more__modal .modal__info {
        padding: 20px;
    }
    .more__modal .modal__contacts {
        grid-template-columns: 1fr;
    }
    .review__modal .modal__container {
        padding: 70px 10px 50px 10px;
    }
    .review__modal .modal__user {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .review__modal .modal__user-name,
    .review__modal .modal__user-surname {
        max-width: 100%;
    }
    .form__modal .modal__container {
        min-width: 100% !important;
        padding: 50px 10px;
    }
    .form__modal .modal__item-picture {
        display: none;
    }
    .form__modal .modal__list {
        grid-template-columns: 1fr;
        row-gap: 15px;
        width: 100%;
    }
    .form__modal .modal__footer {
        -moz-column-gap: 10px;
        column-gap: 10px;
    }
    .form__modal .modal__step {
        font-size: 11px;
        line-height: 15px;
    }
}
.menu .menu__list {
    position: relative;
    left: -90px;
}
.menu_active .menu__list {
    left: 0;
}
.wedding-page * {
    list-style: none !important;
}
.promo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 176px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 2;
}
.promo__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    max-width: 100%;
    width: 100%;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.promo__bg_1920 {
    display: none;
}
.promo__bg.animate {
    -webkit-animation: animatePromoBg 0.75s 0.8s forwards;
    animation: animatePromoBg 0.75s 0.8s forwards;
}
.promo__salut {
    display: none;
    position: absolute;
    width: 100%;
    opacity: 1;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 3;
}
.promo__salut_1 {
    top: 0;
    right: 0;
    max-width: 535px;
    max-height: 413px;
}
.promo__salut_1.animate {
    -webkit-animation: animateOnlyOpacity 0.5s 4s forwards;
    animation: animateOnlyOpacity 0.5s 4s forwards;
}
.promo__salut_2 {
    top: 25.8%;
    right: 18.9%;
    max-width: 453px;
    max-height: 324px;
}
.promo__salut_2.animate {
    -webkit-animation: animateOnlyOpacity 0.5s 3s forwards;
    animation: animateOnlyOpacity 0.5s 3s forwards;
}
.promo__salut_3 {
    top: 0;
    right: 31.3%;
    max-width: 551px;
    max-height: 283px;
}
.promo__salut_3.animate {
    -webkit-animation: animateOnlyOpacity 0.5s 5s forwards;
    animation: animateOnlyOpacity 0.5s 5s forwards;
}
.promo__salut_4 {
    top: 0;
    left: 0;
    max-width: 576px;
    max-height: 305px;
}
.promo__salut_4.animate {
    -webkit-animation: animateOnlyOpacity 0.5s 4.5s forwards;
    animation: animateOnlyOpacity 0.5s 4.5s forwards;
}
.promo__salut_5 {
    top: 228px;
    left: 0;
    max-width: 440px;
    max-height: 309px;
}
.promo__salut_5.animate {
    -webkit-animation: animateOnlyOpacity 0.5s 3.5s forwards;
    animation: animateOnlyOpacity 0.5s 3.5s forwards;
}
.promo__title {
    font-weight: 700;
    font-size: 96px;
    line-height: 116px;
    color: #fff;
    transform: translateY(100%);
    opacity: 1;
    z-index: 4;
}
.promo__title.animate {
    -webkit-animation: animateFadeInBottom 0.75s 0.75s
        forwards;
    animation: animateFadeInBottom 0.75s 0.75s forwards;
}
.promo .swiper {
    margin-top: 30px;
    max-width: 838px;
    width: 100%;
    height: 110px;
    background: linear-gradient(
        95.61deg,
        #ff00c7 0,
        #7000ff 98.87%
    );
    border-radius: 10px;
    transform: translateY(100%);
    opacity: 1;
    z-index: 4;
}
.promo .swiper.animate {
    -webkit-animation: animateFadeInBottom 0.75s 1.5s
        forwards;
    animation: animateFadeInBottom 0.75s 1.5s forwards;
}
.promo .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #fff;
}
.promo__descr {
    margin-top: 30px;
    max-width: 710px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #fff;
}
.promo__next {
    opacity: 1;
}
.promo__next.animate {
    -webkit-animation: animateFadeInNextBtn 0.75s 5s
            forwards,
        animNextBtn 2s 6.5s infinite;
    animation: animateFadeInNextBtn 0.75s 5s forwards,
        animNextBtn 2s 6.5s infinite;
}
.wedding-page {
    display: block;
}
.wedding {
    position: relative;
    display: flex;
}
.wedding__question {
    padding: 50px;
    max-width: 400px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../../public/imgwebp/pages/main/wedding/wedding-bg.webp)
        center center/cover no-repeat;
}
.wedding__question-title span {
    display: inline-block;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #fff;
    opacity: 1;
}
.wedding__question-title span.animate {
    -webkit-animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
    animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
}
.wedding__question-descr {
    margin-top: 30px;
    color: #fff;
    opacity: 1;
}
.wedding__question-descr.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.wedding__button {
    margin-top: 50px;
    transform: translateX(30px);
    opacity: 1;
    position: relative;
    padding: 16px 37px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid currentColor;
    overflow: hidden;
    transition: color 0.5s;
}
.wedding__button::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .wedding__button:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .wedding__button:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.wedding__button span {
    position: relative;
    z-index: 1;
}
.wedding__button_menu {
    color: #11255f;
    background-color: #fff;
}
.wedding__button_menu::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .wedding__button_menu:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
}
@media (min-width: 992px) {
    .wedding__button:hover::before {
        width: 800px;
        height: 800px;
    }
}
.wedding__button.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__categories {
    padding: 40px 130px 40px 100px;
    max-width: 58.5%;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
.wedding__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -moz-column-gap: 60px;
    column-gap: 60px;
    row-gap: 30px;
}
.wedding__item {
    max-width: 300px;
    width: 100%;
}
.wedding__item-title {
    overflow: hidden;
}
.wedding__item-title span {
    display: inline-block;
    transform: translateX(30px);
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #11255f;
    opacity: 1;
}
.wedding__item-title_smoke span.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-title_heart span.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-title_salute span.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-title_fire span.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-descr {
    transform: translateX(30px);
    margin-top: 15px;
    height: 120px;
    opacity: 1;
}
.wedding__item-descr_smoke.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-descr_heart.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-descr_salute.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-descr_fire.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-more {
    transform: translateX(30px);
    margin-top: 20px;
    font-weight: 700;
    color: #11255f;
    position: relative;
    opacity: 1;
}
.wedding__item-more::after,
.wedding__item-more::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.wedding__item-more::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.wedding__item-more::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .wedding__item-more:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .wedding__item-more:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.wedding__item-more:after {
    width: 100%;
    bottom: 0;
    background-color: #11255f;
}
@media (min-width: 992px) {
    .wedding__item-more:hover::after {
        background-color: #11255f;
        -webkit-animation: adjustWidth 0.6s forwards;
        animation: adjustWidth 0.6s forwards;
    }
}
.wedding__item-more_smoke.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-more_heart.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-more_salute.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-more_fire.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-link {
    transform: translateX(30px);
    margin-top: 20px;
    max-width: 300px;
    width: 100%;
    max-height: 170px;
    position: relative;
    opacity: 1;
}
.wedding__item-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 10, 101, 0.5);
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.5s;
}
@media (min-width: 992px) {
    .wedding__item-link:hover::after {
        opacity: 0;
    }
}
.wedding__item-link_smoke.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-link_heart.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-link_salute.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-link_fire.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.wedding__item-link::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    width: 68px;
    height: 68px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.wedding__item-link_smoke::before {
    background-image: url(../../public/img/pages/main/icons/smoke.svg);
}
.wedding__item-link_heart::before {
    background-image: url(../../public/img/pages/main/icons/heart.svg);
}
.wedding__item-link_salute::before {
    background-image: url(../../public/img/pages/main/icons/salute.svg);
}
.wedding__item-link_fire::before {
    background-image: url(../../public/img/pages/main/icons/fire.svg);
}
.wedding__item-img {
    display: flex;
    position: relative;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.wedding .swiper-container {
    position: relative;
    display: none;
}
@media (max-width: 991px) {
    .swiper-slide--hide {
        display: none !important;
    }
}
@media (min-width: 1919px) {
    .swiper-slide--hide {
        display: none !important;
    }
}
.assortment {
    position: relative;
    display: flex;
}
.assortment__container {
    max-width: 400px;
    width: 100%;
    background: linear-gradient(
        0deg,
        rgba(7, 27, 85, 0.7),
        rgba(7, 27, 85, 0.7)
    );
}
.assortment__wrapper {
    padding: 80px 80px 80px 50px;
    width: 100%;
    height: 100%;
    background-image: url(../../public/imgwebp/pages/main/assortment/festivalnie-shari.webp);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: background-image 0.2s;
}
.assortment__title {
    overflow: visible;
}
.assortment__title span {
    display: inline-block;
    max-width: 440px;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #fff;
    opacity: 1;
}
.assortment__title span.animate {
    -webkit-animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
    animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
}
.assortment__list {
    margin-top: 50px;
}
.assortment__item:not(:first-child) {
    margin-top: 30px;
}
.assortment__item-btn {
    display: inline-block;
    transform: translateY(30px);
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: left;
    color: #c9c9c9;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s;
}
.assortment__item-btn:hover {
    color: #fff;
}
.assortment__item-btn_festival.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.5s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.5s linear
        forwards;
}
.assortment__item-btn_salute.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.55s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.55s linear
        forwards;
}
.assortment__item-btn_profi.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.6s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.6s linear
        forwards;
}
.assortment__item-btn_fountains.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.65s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.65s linear
        forwards;
}
.assortment__item-btn_rockets.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.7s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.7s linear
        forwards;
}
.assortment__item-btn_crackers.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.75s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.75s linear
        forwards;
}
.assortment__item-btn_candles.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.8s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.8s linear
        forwards;
}
.assortment__item-btn_sparkles.animate {
    -webkit-animation: animateFadeInRightHalf 0.7s 0.85s
        linear forwards;
    animation: animateFadeInRightHalf 0.7s 0.85s linear
        forwards;
}
.assortment__item-btn_active {
    opacity: 1 !important;
}
.assortment__tabs {
    position: relative;
    max-width: 82%;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}
.assortment__tabs-item {
    display: none;
    top: 0;
    left: 0;
    padding: 50px 54px 50px 50px;
    max-width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fff;
    pointer-events: none;
}
.assortment__tabs-item_active {
    display: block;
    pointer-events: unset;
}
.assortment__images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    max-height: 550px;
}
.assortment__images_animate {
    opacity: 1;
}
.assortment__images_animate.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.assortment__images-item {
    position: relative;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    cursor: pointer;
}
.assortment__images-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 5px;
    transition: background-color 0.2s;
    pointer-events: none;
}
.assortment__images-item::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 350%);
    width: 72px;
    height: 72px;
    background: url(../../public/img/pages/main/assortment/icons/zoom.svg)
        center center/cover no-repeat;
    transition: transform 0.4s;
    pointer-events: none;
}
.assortment__images-item:hover::before {
    background-color: rgba(0, 10, 101, 0.7);
}
.assortment__images-item:hover::after {
    transform: translate(-50%, -50%);
}
.assortment__images-item_1 {
    grid-column: 1/2;
    grid-row: 1/3;
}
.assortment__images-item_2 {
    grid-column: 2/3;
    grid-row: 1/2;
}
.assortment__images-item_3 {
    grid-column: 3/5;
    grid-row: 1/2;
}
.assortment__images-item_4 {
    grid-column: 2/3;
    grid-row: 2/3;
}
.assortment__images-item_5 {
    grid-column: 3/4;
    grid-row: 2/3;
}
.assortment__images-item_6 {
    grid-column: 4/5;
    grid-row: 2/3;
}
.assortment__images-link {
    width: 100%;
    height: 100%;
}
.assortment__images-img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    border-radius: 5px;
}
.assortment__swiper {
    display: none;
}
.assortment__info {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.assortment__info-wrapper {
    max-width: 400px;
}
.assortment__info-title {
    font-size: 24px;
    line-height: 36px;
    color: #11255f;
}
.assortment__info-title_festival {
    transform: translateX(30px);
    opacity: 1;
}
.assortment__info-title_festival.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.assortment__info-descr {
    margin-top: 30px;
}
.assortment__info-descr_festival {
    transform: translateX(30px);
    opacity: 1;
}
.assortment__info-descr_festival.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.assortment__info-link {
    margin-top: 30px;
    font-weight: 700;
    color: #11255f;
    position: relative;
}
.assortment__info-link::after,
.assortment__info-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.assortment__info-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.assortment__info-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .assortment__info-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .assortment__info-link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.assortment__info-link:after {
    width: 100%;
    bottom: 0;
    background-color: #11255f;
}
@media (min-width: 992px) {
    .assortment__info-link:hover::after {
        background-color: #11255f;
        -webkit-animation: adjustWidth 0.6s forwards;
        animation: adjustWidth 0.6s forwards;
    }
}
.assortment__info-link_festival {
    transform: translateX(30px);
    opacity: 1;
}
.assortment__info-link_festival.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.assortment__video {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    border-radius: 10px;
}
.assortment__video-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 370px;
    max-height: 330px;
    border-radius: 10px;
    cursor: pointer;
}
.assortment__video-wrapper_festival {
    transform: translateX(30px);
    opacity: 1;
}
.assortment__video-wrapper_festival.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s forwards;
    animation: animateFadeInRight 0.7s 0.5s forwards;
}
.assortment__video-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    background: url(../../public/img/pages/main/assortment/icons/play-video.svg)
        center center/cover no-repeat;
    pointer-events: none;
}
.assortment__video-wrapper_active::after {
    display: none;
}
.final {
    position: relative;
    padding: 20px;
    background-color: #fff;
}
.final__button--hidden {
    display: none !important;
}
.final__descr {
    font-size: 16px;
    line-height: 24px;
    width: 70%;
    text-align: justify;
    font-weight: 600;
}
.final .final__item_special2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.final .final__item-link--nohover {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.final .final__item--special {
    width: 35%;
    height: auto;
}
.final .final__item--special img {
    height: 100%;
    width: 100%;
}
.final__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #11255f;
    overflow: hidden;
}
.final__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    padding-right: 15px;
}
.final__title span {
    display: inline-block;
    opacity: 1;
}
.final__title span.animate {
    -webkit-animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
    animation: animateFadeInBottom 0.7s 0.25s linear
        forwards;
}
.final .swiper {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    width: 290px;
    height: 70px;
    background: linear-gradient(
        95.61deg,
        #ff00c7 0,
        #7000ff 98.87%
    );
    border-radius: 10px;
    opacity: 1;
}
.final .swiper.animate {
    -webkit-animation: animateFadeInBottom 0.71s 0.25s
        linear forwards;
    animation: animateFadeInBottom 0.71s 0.25s linear
        forwards;
}
.final .swiper-slide {
    font-weight: 800;
    font-size: 40px;
    line-height: 63px;
    text-align: center;
    color: #f1f1f1;
}
.final__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0 auto;
    width: 380px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #11255f;
    overflow: hidden;
    transition: color 0.5s;
    margin: 0;
    opacity: 1;
}
.final__button::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .final__button:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .final__button:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.final__button span {
    position: relative;
    z-index: 1;
}
.final__button::before {
    background-color: #11255f;
    transition: width 0.6s, height 0.6s;
}
@media (min-width: 992px) {
    .final__button:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
    .final__button:hover::before {
        width: 1111px;
        height: 1111px;
    }
}
@media (max-width: 767px) {
    .final__button {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .final__button {
        margin-top: 30px;
    }
}
.final__button.animate {
    -webkit-animation: animateFadeInRight 0.71s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.71s 0.5s linear forwards;
}
.final__button_nopopup {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0 auto;
    width: 380px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #11255f;
    overflow: hidden;
    transition: color 0.5s;
    margin: 0;
    opacity: 1;
    background: #f9e12d !important;
    height: 60px;
    flex-direction: row-reverse;
    line-height: 22px;
}
.final__button_nopopup::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .final__button_nopopup:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .final__button_nopopup:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.final__button_nopopup span {
    position: relative;
    z-index: 1;
}
.final__button_nopopup::before {
    background-color: #11255f;
    transition: width 0.6s, height 0.6s;
}
@media (min-width: 992px) {
    .final__button_nopopup:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
    .final__button_nopopup:hover::before {
        width: 1111px;
        height: 1111px;
    }
}
@media (max-width: 767px) {
    .final__button_nopopup {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .final__button_nopopup {
        margin-top: 30px;
    }
}
.final__button_nopopup:hover svg path {
    stroke: #fff;
}
.final__button_nopopup:hover svg {
    position: relative;
    z-index: 3;
}
.final__button_nopopup.animate {
    -webkit-animation: animateFadeInRight 0.71s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.71s 0.5s linear forwards;
}
.final__button--main {
    position: relative;
    width: 100%;
}
.final__wrapper {
    display: flex;
    -moz-column-gap: 10px;
    column-gap: 10px;
    margin-top: 50px;
}
.final__organize {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
}
.final__organize-wrapper {
    position: relative;
    max-width: 35.7%;
    width: 100%;
    max-height: 665px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    transform: translateX(30px);
    opacity: 1;
}
.final__organize-wrapper.animate {
    -webkit-animation: animateFadeInImg 0.7s 0.5s linear
        forwards;
    animation: animateFadeInImg 0.7s 0.5s linear forwards;
}
.final__organize-info {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    z-index: 2;
}
.final__organize-text {
    display: inline-block;
    max-width: 75%;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}
.final__list {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
    max-width: 100%;
    width: 100%;
    opacity: 1;
    height: 760px;
    max-height: 880px;
    margin-top: 60px;
}
.final__item-link{
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	width: 100%;
	min-height: 200px;
}
.final__item-text {
	color: #f9e12d;
	
    font-size: clamp(12px, 1.2em, 20px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}
.final__wan{
	grid-column: 1 / 2;
    grid-row: 1 / 3;
}
.final__tu{
	grid-column: 1 / 2;
    grid-row: 3 / 3;
}
.final__item_fire{
	grid-column: 2 / 2;
    grid-row: 1 / 2;
}
.final__item_heart{
	grid-column: 3 / 3;
    grid-row: 1 / 3;
}
.final__item_sparkles{

}
.final__item_pyrotechnic{

}
.final__item_wedding{
	grid-column: 2 / 2;
    grid-row: 2 / 4;
}
.final__item_smoke{
	grid-column: 3 / 5;
    grid-row: 3 / 3;
}
.final__list.animate {
    -webkit-animation: animateFadeInRight 0.7s 0.5s linear
        forwards;
    animation: animateFadeInRight 0.7s 0.5s linear forwards;
}
.final__item {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
}

.final__item_weddding {
    background-image: url(../../public/img/pages/main/new/1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    color: #fff;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 5px;
}


.final__item-link {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.final__item-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 10, 101, 0.5);
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.5s;
}
@media (min-width: 992px) {
    .final__item-link:hover::after {
        opacity: 0;
    }
}
.final__item-link::after {
    border-radius: 5px;
}

.final__item-text-wrapper {
    position: absolute;
    left: 15px;
    bottom: 17px;
    border-radius: 2px;
    z-index: 1;
	transition: 300ms;

	background: #d1d1d18a;
    padding: 5px 9px;
	border-radius: 8px;
     max-width: 85%;
}
.final__item .final__button_nopopup {
	background: #d1d1d18a !important;
	color: #f9e12d;
}
.final__item .final__button_nopopup svg path{
	stroke: #f9e12d;
}
.final__item-link:hover  .final__item-text-wrapper{
    bottom: -40px;

}
.final__item-img {
    display: flex;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
}
.final__item-link--nohover:after {
    display: none;
}
.final__item-link--nohover img {
    max-height: calc(220px + 80 * (100vw - 1450px) / 470);
}
.tabs__item {
    display: none;
}
.tabs__item.active {
    display: block;
}
.header__list-link--tab {
    background: rgba(0, 0, 0, 0);
}
@media (max-width: 1200px) {
    .final__descr {
        width: 100%;
        margin-bottom: 30px;
    }
}
.header_inetmagazlink{
    display: none;
}
.main_h2{
    display: none;
}



@media (max-width: 991px) {
    .final__descr {
        margin-top: 60px;
    }
    .callback {
        display: none;
    }
    .final__item-link{
        min-height: 300px;
    }
    .header_inetmagazlink{
        display: block;
        position: absolute;
        color: #fff;
        padding: 12px 20px;
        text-align: center;
        bottom: -45px;
        left: 0px;
        border: 2px solid #fff;
        width: 100%;
        background: #061f67;    }
    .header_inetmagazlink span{
        color: #f5de30;
    }
}
@media (max-width: 1920px) {
    .promo__bg_1920 {
        display: block;
    }
    .promo__bg_1919 {
        display: none;
    }
    .promo__salut {
        display: block;
    }
}
@media (max-width: 1919px) {
    .promo {
        padding: 50px 130px;
    }
    .promo__bg_1920 {
        display: none;
    }
    .promo__bg_1919 {
        display: block;
    }
    .promo__salut {
        display: none;
    }
    .promo__title {
        font-size: 86px;
        line-height: 100px;
    }
    .wedding__question {
        padding: 50px 40px;
    }
    .wedding__categories {
        padding: 40px;
    }
    .wedding__list {
        display: none;
    }
    .wedding__item-descr {
        height: 72px;
    }
    .wedding .swiper-container {
        display: block;
    }
    .wedding .swiper-button-next,
    .wedding .swiper-button-prev {
        top: unset;
        bottom: -60px;
        width: 60px;
        height: 40px;
        border: 1px solid #11255f;
        overflow: hidden;
        transition: color 0.5s;
    }
    .wedding .swiper-button-next::before,
    .wedding .swiper-button-prev::before {
        content: "";
        position: absolute;
        top: var(--y);
        left: var(--x);
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: #fff;
        border-radius: 50%;
        transition: width 0.5s, height 0.5s;
    }
}
@media (max-width: 1919px) and (min-width: 992px) {
    .wedding .swiper-button-next:hover,
    .wedding .swiper-button-prev:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .wedding .swiper-button-next:hover::before,
    .wedding .swiper-button-prev:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
@media (max-width: 1919px) {
    .wedding .swiper-button-next span,
    .wedding .swiper-button-prev span {
        position: relative;
        z-index: 1;
    }
}
@media (max-width: 1919px) {
    .wedding .swiper-button-next::before,
    .wedding .swiper-button-prev::before {
        transition: width 0.5s, height 0.5s;
    }
}
@media (max-width: 1919px) {
    .wedding .swiper-button-next::after,
    .wedding .swiper-button-prev::after {
        font-size: 22px;
        color: #11255f;
        z-index: 1;
    }
}
@media (max-width: 1919px) and (min-width: 992px) {
    .wedding .swiper-button-next:hover,
    .wedding .swiper-button-prev:hover {
        border: 1px solid #11255f;
    }
    .wedding .swiper-button-next:hover::before,
    .wedding .swiper-button-prev:hover::before {
        width: 150px;
        height: 150px;
        background-color: #11255f;
    }
    .wedding .swiper-button-next:hover::after,
    .wedding .swiper-button-prev:hover::after {
        color: #fff;
    }
}
@media (max-width: 1919px) {
    .wedding .swiper-button-next {
        right: 0;
    }
}
@media (max-width: 1919px) {
    .wedding .swiper-button-prev {
        left: unset;
        right: 70px;
    }
}
@media (max-width: 1919px) {
    .wedding__swiper {
        margin-bottom: -60px;
        padding-bottom: 60px;
    }
    .wedding__swiper-link {
        transform: translateX(30px);
        margin-top: 30px;
        opacity: 1;
        position: relative;
    }
    .wedding__swiper-link::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 10, 101, 0.5);
        border-radius: 10px;
        opacity: 1;
        transition: opacity 0.5s;
    }
}
@media (max-width: 1919px) and (min-width: 992px) {
    .wedding__swiper-link:hover::after {
        opacity: 0;
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link::before {
        content: "";
        position: absolute;
        top: 20px;
        left: 20px;
        width: 68px;
        height: 68px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link::after {
        border-radius: 3px;
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link.animate {
        -webkit-animation: animateFadeInRight 0.7s 0.5s
            linear forwards;
        animation: animateFadeInRight 0.7s 0.5s linear
            forwards;
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link_smoke::before {
        background-image: url(../../public/img/pages/main/icons/smoke.svg);
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link_heart::before {
        background-image: url(../../public/img/pages/main/icons/heart.svg);
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link_salute::before {
        background-image: url(../../public/img/pages/main/icons/salute.svg);
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-link_fire::before {
        background-image: url(../../public/img/pages/main/icons/fire.svg);
    }
}
@media (max-width: 1919px) {
    .wedding__swiper-img {
        display: flex;
        max-width: 700px;
        width: 100%;
        max-height: 515px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 3px;
    }
}
@media (max-width: 1919px) {
    .assortment__wrapper {
        padding: 50px 40px 50px 40px;
    }
    .assortment__list {
        margin-top: 40px;
    }
    .assortment__item:not(:first-child) {
        margin-top: 25px;
    }
    .assortment__item-btn {
        font-size: 26px;
        line-height: 32px;
    }
    .assortment__info {
        -moz-column-gap: 10px;
        column-gap: 10px;
    }
    .assortment__info-wrapper {
        max-width: 380px;
    }
    .assortment__video-wrapper {
        max-width: 268px;
        max-height: 290px;
    }
}
@media (max-width: 1919px) {
    .final {
        padding: 20px !important;
    }
    .final__organize-info {
        left: 30px;
        right: 30px;
        bottom: 30px;
    }
  
}
@media (max-width: 1599px) {
    .promo {
        padding: 50px 100px;
    }
    .promo__title {
        font-size: 70px;
        line-height: 80px;
    }
    .promo .swiper {
        margin-top: 25px;
        max-width: 70%;
        height: 100px;
    }
    .promo .swiper-slide {
        font-size: 55px;
        line-height: 65px;
    }
    .wedding__question {
        padding: 50px 45px 50px 15px;
    }
    .wedding__question-descr {
        margin-top: 20px;
    }
    .wedding__categories {
        padding: 50px 40px 50px 30px;
    }
    .wedding__list {
        gap: 50px;
    }
    .assortment__wrapper {
        padding: 50px 30px;
    }
    .assortment__item-btn {
        font-size: 23px;
        line-height: 29px;
    }
    .assortment__tabs-item {
        padding: 50px 25px;
    }
    .assortment__info-wrapper {
        max-width: 370px;
    }
    .assortment__video-wrapper {
        max-width: 249px;
        max-height: 269px;
    }
    .final__wrapper {
        -moz-column-gap: 30px;
        column-gap: 30px;
    }
    .final__organize-info {
        bottom: 20px;
    }
}
@media (max-width: 1449px) {
    .wedding__item-descr {
        height: 96px;
    }
}
@media (max-width: 1439px) {
    .promo {
        padding: 50px 40px;
    }
    .promo__title {
        font-size: 60px;
        line-height: 70px;
    }
    .promo .swiper {
        margin-top: 20px;
        height: 90px;
    }
    .promo .swiper-slide {
        font-size: 50px;
        line-height: 60px;
    }
    .promo__next {
        display: block;
    }
    .wedding__question {
        padding: 50px 45px 50px 15px;
    }
    .wedding__question-descr {
        margin-top: 20px;
    }
    .wedding__categories {
        padding: 50px 40px 50px 30px;
    }
    .assortment__wrapper {
        padding: 50px 15px;
    }
    .assortment__container {
        max-width: 38%;
    }
    .assortment__list {
        margin-top: 30px;
    }
    .assortment__item:not(:first-child) {
        margin-top: 20px;
    }
    .assortment__item-btn {
        font-size: 18px;
        line-height: 22px;
    }
    .assortment__tabs {
        max-width: 62%;
    }
    .assortment__tabs-item {
        padding: 50px 30px;
        max-width: 100%;
    }
    .assortment__images {
        display: none;
    }
    .assortment__swiper {
        display: block;
    }
    .assortment__swiper-img {
        display: flex;
        max-width: 300px;
        width: 100%;
        max-height: 440px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .assortment__swiper-link_festival {
        transform: translateX(30px);
        opacity: 0;
    }
    .assortment__swiper-link_festival.animate {
        -webkit-animation: animateFadeInRight 0.7s 0.5s
            forwards;
        animation: animateFadeInRight 0.7s 0.5s forwards;
    }
    .assortment .swiper-button-next,
    .assortment .swiper-button-prev {
        top: 85%;
        width: 60px;
        height: 40px;
        border: 1px solid #fff;
        overflow: hidden;
        transition: color 0.5s;
    }
    .assortment .swiper-button-next::before,
    .assortment .swiper-button-prev::before {
        content: "";
        position: absolute;
        top: var(--y);
        left: var(--x);
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: #fff;
        border-radius: 50%;
        transition: width 0.5s, height 0.5s;
    }
}
@media (max-width: 1439px) and (min-width: 992px) {
    .assortment .swiper-button-next:hover,
    .assortment .swiper-button-prev:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .assortment .swiper-button-next:hover::before,
    .assortment .swiper-button-prev:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
@media (max-width: 1439px) {
    .assortment .swiper-button-next span,
    .assortment .swiper-button-prev span {
        position: relative;
        z-index: 1;
    }
}
@media (max-width: 1439px) {
    .assortment .swiper-button-next::before,
    .assortment .swiper-button-prev::before {
        transition: width 0.5s, height 0.5s;
    }
}
@media (max-width: 1439px) {
    .assortment .swiper-button-next::after,
    .assortment .swiper-button-prev::after {
        font-size: 25px;
        color: #fff;
        z-index: 1;
    }
}
@media (max-width: 1439px) and (min-width: 992px) {
    .assortment .swiper-button-next:hover::before,
    .assortment .swiper-button-prev:hover::before {
        width: 150px;
        height: 150px;
    }
    .assortment .swiper-button-next:hover::after,
    .assortment .swiper-button-prev:hover::after {
        color: #11255f;
    }
}
@media (max-width: 1439px) {
    .assortment .swiper-button-next {
        right: 80px;
    }
}
@media (max-width: 1439px) {
    .assortment .swiper-button-prev {
        left: unset;
        right: 150px;
    }
}
@media (max-width: 1439px) {
    .assortment__info {
        margin-top: 50px;
    }
    .assortment__info-wrapper {
        max-width: unset;
    }
}
@media (max-width: 1439px) {
    .assortment__video-wrapper {
        display: none;
    }
}
@media (max-width: 1439px) {
    .final__title {
        max-width: unset;
    }
    .final__wrapper {
        -moz-column-gap: 10px;
        column-gap: 10px;
        margin-top: 30px;
    }
    .final__organize-wrapper {
        max-width: 39.8%;
        max-height: 400px;
    }
    .final__organize-info {
        left: 15px;
        right: 15px;
        bottom: 15px;
    }
 

}
@media (max-width: 1439px) {
    .contacts__map {
        height: 48vh;
        height: calc(var(--vh, 1vh) * 48);
    }
}
@media (max-width: 1299px) {
    .wedding__item-descr {
        height: 120px;
    }
}
@media (max-width: 1199px) {
    .final {
        height: auto;
    }
    .final__item_special2 {
        display: none !important;
    }
    .final__title span {
        transform: translate(0);
        opacity: 1;
    }
    .final .swiper {
        transform: translate(0);
        opacity: 1;
    }
    .final__button {
        display: flex;
        transform: translate(0);
        opacity: 1;
    }
    .final__title-wrapper {
        align-items: center;
        flex-direction: column;
    }
    .final__title-wrapper .animated--mr {
        text-align: center;
        margin-bottom: 20px;
    }
    .final__title-wrapper .final__button--hidden {
        display: flex !important;
        width: 380px;
        margin-top: 20px;
    }
    .final__title-wrapper .final__descr--m2 {
        display: block !important;
        text-align: center;
        margin-bottom: 20px;
    }
    .final__wrapper {
        display: block;
    }
    .final__organize-wrapper {
        transform: translate(0);
        max-width: 80%;
        max-height: unset;
        height: 766px;
        opacity: 1;
    }
    .final__organize-info {
        left: 25px;
        right: 25px;
        bottom: 25px;
    }
   
    .promo .swiper {
        max-width: 75%;
        height: 80px;
    }
    .promo .swiper-slide {
        font-size: 42px;
        line-height: 52px;
    }
    .wedding__question {
        padding-right: 20px;
    }
    .wedding__item-descr {
        height: auto;
    }
    .assortment__container {
        max-width: 40%;
    }
    .assortment__tabs {
        max-width: 60%;
    }
    .assortment__tabs-item {
        padding: 50px 30px 30px 15px;
    }
    .final .swiper {
        width: 230px;
        height: 60px;
    }
    .final .swiper-slide {
        font-size: 33px;
        line-height: 55px;
    }
    .final__organize-wrapper {
        max-width: 100%;
        max-height: 300px;
    }
    .final__organize-text {
        font-size: 18px;
        line-height: 26px;
    }
    .final__list {
        max-width: 100%;
        padding-bottom: 40px;
    }
  
    .final__item_wedding a {
        display: flex;
        justify-content: center;
    }
    .final .final__list {
        margin-top: 15px;
    }
   
}
@media (max-width: 1109px) {
    .wedding__item-descr {
        height: 96px;
    }
}
@media (max-width: 1099px) {
    .assortment .swiper-button-prev {
        right: 40%;
    }
}
@media (max-width: 991px) {
    .header {
        transform: translate(0);
    }
    .promo {
        display: none;
        padding: 50px 25px;
        width: 100%;
        height: calc(100vh - 90px);
        background: url(../../public/imgwebp/pages/main/promo-bg.webp)
            center center/cover no-repeat;
    }
    .promo__title {
        font-size: 80px;
        line-height: 95px;
        transform: translateY(0);
        opacity: 1;
    }
    .promo .swiper {
        margin-top: 20px;
        max-width: 90%;
        width: 100%;
        height: 90px;
        transform: translateY(0);
        opacity: 1;
    }
    .promo .swiper-slide {
        font-size: 52px;
        line-height: 62px;
    }
    .wedding {
        display: block;
    }
    .wedding .swiper-container {
        display: none !important;
    }
    .wedding__question {
        padding: 120px 25px 50px 20px;
        max-width: 100%;
        height: 82.5vh;
        background-image: url(../../public/imgwebp/pages/main/wedding/wedding-bg-991.webp);
    }
    .wedding__question-title {
        font-size: 40px;
        line-height: 56px;
    }
    .wedding__question-title span {
        transform: translate(0);
        opacity: 1;
    }
    .wedding__question-descr {
        transform: translate(0);
        margin-top: 20px;
        opacity: 1;
    }
    .wedding__button {
        transform: translate(0);
        opacity: 1;
    }
    .wedding__categories {
        padding: 50px 25px;
        max-width: 100%;
        height: auto;
    }
    .wedding__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;
    }
    .wedding__item {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        max-width: unset;
    }
    .wedding__item-title span {
        transform: translate(0);
        opacity: 1;
    }
    .wedding__item-descr {
        transform: translate(0);
        margin-top: 20px;
        max-width: 80%;
        height: auto;
        opacity: 1;
    }
    .wedding__item-more {
        transform: translate(0);
        opacity: 1;
    }
    .wedding__item-link {
        transform: translate(0);
        max-height: 440px;
        max-width: 80%;
        opacity: 1;
    }
    .assortment {
        display: block;
        padding-bottom: 50px;
    }
    .assortment__wrapper {
        height: 61.4vh;
        background-image: url(../../public/imgwebp/pages/main/assortment/bg-991.webp);
    }
    .assortment__title {
        max-width: 470px;
    }
    .assortment__title span {
        transform: translate(0);
        opacity: 1;
    }
    .assortment__info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        -moz-column-gap: 20px;
        column-gap: 20px;
        row-gap: 50px;
        margin-top: 50px;
        padding: 0 25px;
    }
    .assortment__info-img {
        display: flex;
        margin-top: 30px;
        border-radius: 5px;
    }
    .assortment__more {
        padding: 17px;
        max-width: 50%;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
        color: #11255f;
        border: 1px solid #11255f;
    }
    .assortment__more-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .final__title {
        font-size: 20px;
        line-height: 30px;
    }
}
@media (max-width: 767px) {
    .promo {
        padding: 50px 20px;
    }
    .promo__title {
        font-size: 72px;
        line-height: 87px;
    }
    .promo .swiper {
        max-width: 100%;
        height: 80px;
    }
    .promo .swiper-slide {
        font-size: 45px;
        line-height: 55px;
    }
    .final__button--hidden {
        width: 100% !important;
    }
    .wedding__question {
        padding: 120px 20px 50px 20px;
    }
    .wedding__categories {
        padding: 50px 20px;
    }
    .wedding__item-descr {
        max-width: 100%;
    }
    .wedding__item-link {
        max-width: 100%;
    }
    .assortment__info {
        padding: 0 20px;
    }
    .assortment__more {
        max-width: 100%;
    }
    .assortment__more-wrapper {
        margin: 50px 20px 0 20px;
    }
    .final__organize-wrapper {
        max-width: 100%;
        height: 600px;
    }
    .final .swiper {
        height: 35px;
    }
    .final .swiper-slide {
        font-size: 20px;
        line-height: 32px;
    }
    .subscribe__text-wrapper {
        display: flex;
        flex-direction: column;
    }
    .subscribe__text-wrapper .callback {
        display: flex;
        background: #fff;
        flex-direction: column;
        align-items: center;
    }
    .subscribe__text-wrapper .aga-dev {
        color: #061f67 !important;
    }
    .subscribe__text-wrapper svg, .subscribe__text-wrapper img{
        filter: invert(10%) sepia(18%) saturate(4292%) hue-rotate(198deg) brightness(96%) contrast(107%);
    }
    .subscribe__text-wrapper .callback__form-input {
        border-bottom: 1px solid #102691;
        color: #000;
    }
    .aga-dev{
        gap: 10px;
    }
}
@media (max-width: 729px) {
    .final .swiper {
        margin-left: 0;
        border-radius: 3px;
    }
}
@media (max-width: 575px) {
    .promo {
        padding: 50px 10px;
    }
    .promo__title {
        font-size: 48px;
        line-height: 58px;
    }
    .promo .swiper {
        margin-top: 15px;
        height: 50px;
        border-radius: 5px;
    }
    .promo .swiper-slide {
        font-size: 24px;
        line-height: 29px;
    }
    .wedding__question {
        padding: 120px 20px 40px 20px;
    }
    .wedding__categories {
        padding: 40px 10px;
    }
    .wedding__list {
        gap: 40px;
    }
    .wedding__item {
        padding-top: 10px;
        border-top: 1px solid rgba(0, 10, 101, 0.5);
    }
    .assortment {
        padding-bottom: 40px;
    }
    .assortment__wrapper {
        height: 61.4vh;
        background-image: url(../../public/imgwebp/pages/main/assortment/bg-991.webp);
    }
    .assortment__title {
        max-width: 300px;
    }
    .assortment__info {
        grid-template-columns: 1fr;
        margin-top: 40px;
        padding: 0 10px;
    }
    .assortment__info-wrapper {
        padding-top: 10px;
        border-top: 1px solid rgba(0, 10, 101, 0.5);
    }
    .assortment__info-title {
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
    }
    .assortment__info-descr {
        margin-top: 20px;
    }
    .assortment__more-wrapper {
        margin: 40px 10px 0 10px;
    }
    .final__wrapper {
        margin-top: 40px;
    }
    .final__title {
        word-break: break-word;
    }
    .final__organize-wrapper {
        height: 480px;
    }
    .final__list {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }
    .final__item {
        grid-column: auto;
    }
    .final__item_heart {
		order: 3;

    }
    .final__item_sparkles {
        order: 4;
    }
    .final__item_pyrotechnic {
        order: 3;
        
    }
	.final__wan{
        order: 1;

	}
	.final__descr {
        margin-top: 0px;
		line-height: 110%;
    }
	.final__list{
		max-height: none;
		height: 100%;
	}
	.final{
		display: flex;
        flex-direction: column-reverse;
	}
	.final__tu{
		order: 3;

	}
    .final__item_wedding {
        order: 2;
    }
    .final__item_fire {
        order: 5;
    }
    .final__item_smoke {
        order: 2;
    }
}
@media (max-width: 499px) {
    .final__organize-wrapper {
        height: 420px;
    }
    .final__title-wrapper {
        flex-direction: column !important;
    }
    .final .swiper {
        width: 140px;
    }
    .animated--mr {
        margin-right: 10px;
    }
    .animated {
        text-align: center;
    }
    .final__descr--m2 {
        display: block !important;
        margin-top: 10px;
        text-align: left;
    }
    .final__button {
        width: 100%;
        margin-top: 15px;
    }
}
@media (max-width: 399px) {
    .final__organize-wrapper {
        height: 370px;
    }
    .final__organize-info {
        left: 15px;
        right: 15px;
        bottom: 15px;
    }
}
@media (max-width: 480px) {
    .main {
        margin-top: 90px;
    }
    /* .final {
        display: flex;
        flex-wrap: wrap-reverse;
        padding-top: 0 !important;
        padding-bottom: 15px;
    } */
     .main_h2{
        display: block;
        margin-top: 180px;
        padding: 20px;
        text-align: center;
        color: #061f67;
        font-size: clamp(16px, 1.3em, 24px);
        font-weight: bold;
     }
    .final__wrapper {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
    }
}


