* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Inter;
  src: url(../fonts/InterLight.woff2);
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/InterRegular.woff2);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/InterMedium.woff2);
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/InterSemiBold.woff2);
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/InterBold.woff2);
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/InterExtraBold.woff2);
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "KZ Taurus";
  src: url(../fonts/kztaurus.woff2);
  font-weight: 400;
  font-display: swap;
}
.arrow {
  min-width: 20px;
  min-height: 20px;
  display: inline-block;
  position: relative;
  z-index: 99;
  cursor: pointer;
  transform: rotate(270deg);
}
.arrow span {
  top: 0.5rem;
  position: absolute;
  width: 0.75rem;
  height: 0.1rem;
  background-color: #efefef;
  display: inline-block;
  transition: all 0.2s ease;
}
.arrow span:first-of-type {
  left: 0;
  transform: rotate(45deg);
}
.arrow span:last-of-type {
  right: 0;
  transform: rotate(-45deg);
}
.arrow.active span:first-of-type {
  transform: rotate(-45deg);
}
.arrow.active span:last-of-type {
  transform: rotate(45deg);
}

.header {
  z-index: 10;
}

.header__activity-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popupFos {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6784313725);
  z-index: 50;
  transform: translateY(-200vh);
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupFos .modal__contact-info-address {
  padding: 8px 0;
}
.popupFos.active {
  transform: translateY(0vh);
}
.popupFos .modal__text {
  margin-bottom: 20px;
  font-size: 20px;
}
.popupFos_p {
  color: #fff;
  margin-top: 20px;
}
.popupFos_exit {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border: none;
  cursor: pointer;
}
.popupFos_content {
  width: 100%;
  background: #efefef;
  height: 100%;
  max-width: 1000px;
  max-height: 800px;
  background: url(../../public/img/blocks/modal/bg.jpg);
  background-size: cover;
  padding: 25px;
  position: relative;
}
.popupFos_fos {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.popupFos_fos_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}
.popupFos_fos_container-a {
  display: flex;
  flex-direction: column;
}
.popupFos_fos_container-a p {
  color: #fff;
  padding: 5px 10px;
}
.popupFos_fos_box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}
.popupFos_fos_box_chek {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.popupFos_fos_box_chek label {
  background: rgba(238, 238, 238, 0.3490196078);
  line-height: 120%;
  cursor: pointer;
  position: relative;
}
.popupFos_fos_box_chek label img {
  width: 100%;
}
.popupFos_fos_box_chek label input {
  display: none;
}
.popupFos_fos_box_chek label:has(input:checked)::before {
  content: url(../../public/img/check.svg);
  position: absolute;
  stroke: green;
  right: 10px;
  top: 10px;
}

input[type=date] {
  position: relative;
  padding: 4px 0 0 0px !important;
  outline: 0;
  width: -moz-min-content;
  width: min-content;
  margin-top: 0 !important;
  background: rgba(0, 0, 0, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0.49);
  color: #fff;
  font-size: 21px;
  box-sizing: border-box;
  line-height: 44px;
  text-align: center;
  height: 30px;
}

.otvetFos {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__form-input {
  border: none;
  border-bottom: 1px solid #fff;
}

@media (max-width: 1100px) {
  .description__img {
    max-height: calc(var(--vh, 1vh) * 85);
  }
  .description__wrapper {
    max-height: calc(var(--vh, 1vh) * 85);
  }
  .im {
    width: 100%;
  }
}
@media (max-width: 920px) {
  .popupFos .modal__contact-info-address {
    font-size: 12px;
  }
  .subscribe__list {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .modal__contact-info-left {
    display: none;
  }
  .header__list-link.modal__contact-info-address {
    display: none;
  }
  .popupFos_fos_container-b .modal__text {
    display: none;
  }
  .popupFos_p {
    display: none;
  }
  .popupFos_fos_container {
    grid-template-columns: 1fr;
  }
  .popupFos_fos_box_chek {
    display: grid;
    grid-template-columns: 1fr;
  }
  .description__more {
    margin: 0;
  }
  .modal__item-img {
    display: none;
  }
  .modal__contact-info-right {
    height: -moz-min-content;
    height: min-content;
  }
  .popupFos_fos_box_chek label:has(input:checked)::before {
    top: 5px;
  }
  .popupFos_fos_box_chek label {
    background: rgba(238, 238, 238, 0.3490196078);
  }
  .popupFos_fos_container-b {
    height: 10px;
  }
  .modal__form-input {
    max-width: 100%;
  }
  .popupFos_fos .description__more {
    top: -100px;
  }
}
.subscribe__list {
  --g: 6px; /* the gap */
  display: grid;
  width: 100%; /* the size */
  aspect-ratio: 1;
  grid: auto-flow 1fr/repeat(3, 1fr);
  gap: var(--g);
}

.subscribe__item {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: grayscale(1);
  cursor: pointer;
  transition: 0.5s;
  max-width: 540px;
  width: 100%;
  max-height: 540px;
}
.subscribe__item:hover {
  filter: grayscale(0);
}
.subscribe__item-img {
  width: 100%;
}
.subscribe__item:nth-child(2) {
  grid-area: 1/2/span 2/span 2;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, calc(50% + var(--g) / 4) 100%, 0 calc(50% - var(--g) / 4));
          clip-path: polygon(0 0, 100% 0, 100% 100%, calc(50% + var(--g) / 4) 100%, 0 calc(50% - var(--g) / 4));
}
.subscribe__item:nth-child(3) {
  grid-area: 2/1/span 2/span 2;
  -webkit-clip-path: polygon(0 0, calc(50% - var(--g) / 4) 0, 100% calc(50% + var(--g) / 4), 100% 100%, 0 100%);
          clip-path: polygon(0 0, calc(50% - var(--g) / 4) 0, 100% calc(50% + var(--g) / 4), 100% 100%, 0 100%);
}

/* slider */
.stages_cheked {
  display: none;
}

@media (max-width: 991px) {
  .stages_cheked {
    display: block;
  }
  .stages_cheked ol {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    gap: 20px;
  }
  .popupFos_fos_container-a {
    height: 70vh;
    overflow-y: scroll;
}
.description__info {
  max-height: none;
  height: 200px;
  min-height: 200px;
}
.description__img, .description__wrapper {
  max-height: none;
}
  .stages_cheked li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #f5de30;
    border-radius: 50%;
    border: 2px solid #061f67;
    background: rgba(6, 31, 103, 0.5215686275);
    cursor: pointer;
  }
  .stages_cheked li.active {
    border: 2px solid #061f67;
    background: #061f67;
    color: #fff;
  }
  .stages__list {
    display: flex;
    gap: 0;
  }
  .stages__item {
    overflow: hidden;
    width: 0;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    height: 280px;
    opacity: 0;
    position: relative;
  }
  .stages__item::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #061f67;
    transition: 300ms;
  }
  .stages__item-title {
    text-align: center;
  }
  .stages__item-descr {
    text-align: center;
  }
  .stages__item.active {
    width: 100%;
    opacity: 1;
  }
  .stages__item.active::after {
    content: "";
    transition-delay: 250ms;
    left: -100%;
  }
  .description {
    display: flex;
    flex-direction: column-reverse;
  }
}
/* slider end *//*# sourceMappingURL=glob.css.map */