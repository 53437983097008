@-webkit-keyframes animateHeader {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes animateHeader {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@-webkit-keyframes animatePromoBg {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes animatePromoBg {
    0% {
        transform: translate(-50%, -50%) scale(0.1);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}
@-webkit-keyframes animNextBtn {
    0% {
        transform: translateX(-30%);
    }
    50% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(-30%);
    }
}
@keyframes animNextBtn {
    0% {
        transform: translateX(-30%);
    }
    50% {
        transform: translateX(30%);
    }
    100% {
        transform: translateX(-30%);
    }
}
@-webkit-keyframes animateFadeInBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes animateFadeInBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInRight {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes animateFadeInRight {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInRightHalf {
    0% {
        transform: translateX(30px);
        opacity: 0;
        pointer-events: none;
    }
    100% {
        transform: translateY(0);
        opacity: 0.5;
        pointer-events: unset;
    }
}
@keyframes animateFadeInRightHalf {
    0% {
        transform: translateX(30px);
        opacity: 0;
        pointer-events: none;
    }
    100% {
        transform: translateY(0);
        opacity: 0.5;
        pointer-events: unset;
    }
}
@-webkit-keyframes animateFadeInNextBtn {
    0% {
        transform: translate(-30%, 100%);
        opacity: 0;
    }
    100% {
        transform: translate(-30%, 0);
        opacity: 1;
    }
}
@keyframes animateFadeInNextBtn {
    0% {
        transform: translate(-30%, 100%);
        opacity: 0;
    }
    100% {
        transform: translate(-30%, 0);
        opacity: 1;
    }
}
@-webkit-keyframes animateOnlyOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes animateOnlyOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes animateFadeInImg {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes animateFadeInImg {
    0% {
        transform: translateX(30px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
body {
    margin: 0;
}
*,
::after,
::before {
    box-sizing: border-box;
}
* {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3e3e3e;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}
a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}
ol,
ul {
    list-style: none;
}
img {
    max-width: 100%;
}
address,
b,
blockquote,
button,
fieldset,
figure,
li,
ol,
p,
span,
time,
ul {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    letter-spacing: inherit;
    font-style: inherit;
}
strong {
    font-weight: 700;
}
fieldset {
    border: none;
}
button {
    background-color: #fff;
    border: none;
    cursor: pointer;
}
time {
    display: inline-block;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none;
}
.sr-only {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
}
.preload {
    display: none;
}
.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #071b55;
    z-index: 10;
}
.overlay_inactive {
    display: none;
}
.main__wrapper {
    display: none;
}
.delete-animation {
    -webkit-animation: none !important;
    animation: none !important;
    transform: translate(0, 0) !important;
    opacity: 1 !important;
}
.section {
    padding: 75px 200px 75px 100px;
    background-color: #fff;
}
.section-first {
    padding: 10px 200px 75px 100px;
}
.section-category {
    padding: 10px 200px 75px 100px;
}
.section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #11255f;
}
@media (max-width: 991px) {
    .swiper-slide--hide {
        display: none !important;
    }
}
@media (max-width: 1919px) {
    .section {
        padding: 75px 150px 75px 70px;
    }
    .section-category {
        padding: 10px 100px 75px 70px;
    }
    .section-first {
        padding: 10px 150px 0px 70px;
    }
}
@media (max-width: 1599px) {
    .section {
        padding: 60px 120px 50px 50px;
    }
    .section-category {
        padding: 10px 70px 50px 50px;
    }
    .section-first {
        padding: 10px 120px 0px 50px;
    }
}
@media (max-width: 1439px) {
    .section {
        padding: 50px 100px 50px 30px;
    }
    .section-category {
        padding: 10px 50px 50px 30px;
    }
    .section-first {
        padding: 10px 100px 0px 30px;
    }
}
@media (max-width: 1199px) {
    .section {
        padding: 50px 50px 50px 30px;
    }
    .section-category {
        padding: 10px 50px 50px 30px;
    }
    .section-first {
        padding: 10px 50px  0px 30px;
    }
}
@media (max-width: 991px) {
    .overlay {
        display: none;
    }
    .main {
        padding-top: 0;
        padding-left: 0;
    }
    .main__swiper {
        display: none;
    }
    .main__wrapper {
        display: block;
    }
    .section {
        padding: 50px 30px 50px 30px;
    }
    .section-category {
        padding: 10px 30px 50px 30px;
    }
    .section-first {
        padding: 10px 30px  0px 30px;
    }
}
@media (max-width: 767px) {
    .section {
        padding: 50px 20px 50px 20px;
    }
    .section-category {
        padding: 10px 20px 50px 20px;
    }
    .section-first {
        padding: 10px 20px  0px 20px;
    }
}
@media (max-width: 575px) {
    .section-title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #11255f;
    }
    .section {
        padding: 40px 10px 40px 10px;
    }
    .section-category {
        padding: 10px 10px 40px 10px;
    }
    .section-first {
        padding: 10px 10px  0px 10px;
    }
    .section-title_1920 {
        display: none;
    }
    .section-title_575 {
        display: block;
    }
}
.alert {
    position: fixed;
    top: 0;
    left: 0;
    padding: 9px;
    width: 100vw;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background-color: #e20000;
    z-index: 4;
}
@media (max-width: 991px) {
    .alert {
        position: static;
        padding-top: 99px;
    }
}
.preloader {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
}
.preloader_active {
    display: flex;
}
.preloader .swiper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 6;
}
.preloader .spinner {
    width: 130px;
    height: 130px;
    border: 6px solid #fff;
    border-radius: 50%;
    position: relative;
    -webkit-animation: preloaderanim 2s infinite linear;
    animation: preloaderanim 2s infinite linear;
    z-index: 7;
}
.preloader .spinner::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #11255f;
    position: absolute;
    top: 9.5px;
    left: 14px;
}
.preloader .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    z-index: 7;
}
@-webkit-keyframes preloaderanim {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes preloaderanim {
    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 1439px) {
    .spinner {
        width: 100px;
        height: 100px;
    }
    .spinner::before {
        top: 1.5px;
    }
}
@media (max-width: 991px) {
    .spinner {
        width: 70px;
        height: 70px;
    }
    .spinner::before {
        top: -4.5px;
    }
}
@media (max-width: 575px) {
    .spinner {
        width: 50px;
        height: 50px;
    }
    .spinner::before {
        top: -6.5px;
    }
}
.header__alive {
    opacity: 0;
    transition: 1s;
    position: absolute;
}
.header__alive1 {
    left: 88px;
}
.header__alive2 {
    left: 93px;
    top: 40px;
}
.header__alive3 {
    left: 105px;
    top: 42px;
}
.header__alive4 {
    left: 98px;
    top: 48px;
}
.header__alive5 {
    left: 104px;
    top: 40px;
}
.header__alive6 {
    left: 88px;
}
.header__alive7 {
    left: 92px;
    top: 40px;
}
.header__alive8 {
    left: 90px;
    top: 42px;
}
.header__alive9 {
    left: 90px;
    top: 42px;
}
.header__alive10 {
    left: 90px;
    top: 42px;
}
.header__alive11 {
    left: 90px;
    top: 42px;
}
.header__logo-blink {
    position: absolute;
    top: 50px;
    left: 250px;
    width: 1px;
    height: 1px;
    background: rgba(0, 0, 0, 0);
    transition: 1s;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 100px;
}
.header__logo-link:hover .header__alive1 {
    opacity: 1;
    transform: translate(76px, -21px);
}
.header__logo-link:hover .header__alive2 {
    transform: translate(51px, 2px);
    opacity: 1;
}
.header__logo-link:hover .header__alive3 {
    transform: translate(28px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive4 {
    transform: translate(80px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive5 {
    transform: translate(36px, -26px);
    opacity: 1;
}
.header__logo-link:hover .header__alive6 {
    transform: translate(68px, 0);
    opacity: 1;
}
.header__logo-link:hover .header__alive7 {
    transform: translate(96px, -33px);
    opacity: 1;
}
.header__logo-link:hover .header__alive8 {
    transform: translate(107px, -15px);
    opacity: 1;
}
.header__logo-link:hover .header__alive9 {
    transform: translate(29px, 2px);
    opacity: 1;
}
.header__logo-link:hover .header__alive10 {
    transform: translate(22px, -9px);
    opacity: 1;
}
.header__logo-link:hover .header__alive11 {
    transform: translate(26px, -22px);
    opacity: 1;
}
.header__logo-link:hover .header__logo-blink {
    box-shadow: -2px 2px 12px 6px #ff0;
}
.header__list-item--pic {
    width: 190px;
    display: flex;
    justify-content: space-between;
}
.header__list-item--pic a {
    transition: 0.5s;
}
.header__list-item--pic a:hover {
    transform: scale(1.15);
}
.header_991 {
    display: none;
}
.header_active {
    transition: max-width 0s 0s;
    max-width: 660px;
}
.header.animate {
    -webkit-animation: animateHeader 0.5s forwards;
    animation: animateHeader 0.5s forwards;
}
.header__logo {
    max-width: 200px;
    width: 100%;
    max-height: 56px;
    -o-object-fit: cover;
    object-fit: cover;
}
.header__logo-circle-gradient-start,
.header__logo-mask-gradient-start {
    transition: all 0.3s;
}
.header__logo-circle-gradient-finish,
.header__logo-mask-gradient-finish {
    transition: all 0.3s;
}
@media (min-width: 992px) {
    .header__logo:hover .header__logo-circle-gradient-start,
    .header__logo:hover .header__logo-mask-gradient-start {
        stop-color: #ffd300;
    }
    .header__logo:hover
        .header__logo-circle-gradient-finish,
    .header__logo:hover .header__logo-mask-gradient-finish {
        stop-color: #fd3256;
    }
}
.header__logo-wrapper {
    padding: 30px 20px 30px 60px;
    height: 12vh;
    height: calc(var(--vh, 1vh) * 12);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #11255f;
}
.header__logo-link {
    max-width: 100%;
    max-height: 100%;
}
.header__activity {
    display: inline-block;
    font-size: 13px;
    line-height: 19px;
    color: #fff;
}
.header__activity-wrapper {
    padding: 3px 20px 3px 60px;
    width: 100%;
    height: 4.8vh;
    background-color: #11255f;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header__info {
    padding: 30px 26px 40px 60px;
    height: 47.2vh;
    height: calc(var(--vh, 1vh) * 47.2);
    background-color: #11255f;
    overflow-y: hidden;
}
.header__info::-webkit-scrollbar {
    width: 5px;
}
.header__info::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}
.header__info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
.header__flex {
    display: flex;
    -moz-column-gap: 15px;
    column-gap: 15px;
    align-items: center;
}
.header__catalogue-link {
    position: relative;
    padding: 16px 37px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid currentColor;
    overflow: hidden;
    transition: color 0.5s;
}
.header__catalogue-link::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .header__catalogue-link:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .header__catalogue-link:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.header__catalogue-link span {
    position: relative;
    z-index: 1;
}
.header__catalogue-link_menu {
    color: #11255f;
    background-color: #fff;
}
.header__catalogue-link_menu::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .header__catalogue-link_menu:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
}
.header__open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 39px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
@media (min-width: 992px) {
    .header__open:hover span:nth-child(2) {
        width: 100%;
    }
}
.header__open span {
    height: 3px;
    background-color: #fff;
}
.header__open span:nth-child(1) {
    width: 39px;
}
.header__open span:nth-child(2) {
    margin-top: 6px;
    max-width: 39px;
    width: 70%;
    transition: width 0.2s, opacity 0.2s;
}
.header__personal {
    display: none;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-top: 22px;
}
@media (min-width: 992px) {
    .header__personal-link:hover .header__personal-icon {
        transform: scale(1.1);
    }
    .header__personal-link:active .header__personal-icon {
        opacity: 0.5;
    }
}
.header__personal-link_cart {
    position: relative;
    margin-top: -1px;
}
.header__personal-icon {
    transition: transform 0.2s;
}
.header__nav {
    margin-top: 30px;
}
.header__list {
    row-gap: 20px;
}
.header__list-item:not(:first-child) {
    margin-top: 20px;
}
.header__list-link {
    color: #fff;
    position: relative;
}
.header__list-link::after,
.header__list-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.header__list-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.header__list-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .header__list-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .header__list-link:active {
        opacity: 0.5;
    }
}
@-webkit-keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.menu {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    max-width: 660px;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #fff;
    opacity: 0;
    transition: transform 0.5s, opacity 0.4s;
    z-index: 4;
}
.menu_active {
    transition: transform 0.5s, opacity 0.4s;
    transform: translateX(0);
    opacity: 1;
}
.menu__logo {
    max-width: 200px;
    width: 100%;
    max-height: 56px;
}
.menu__logo-wrapper {
    padding: 40px 60px 26px 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.menu__info {
    display: flex;
    flex-direction: column;
    padding: 36px 60px 40px 60px;
    height: calc(100vh - 199px);
    height: calc(var(--vh, 1vh) * 100 - 199px);
}
.menu__close {
    width: 40px;
    height: 40px;
    background: url(../../public/img/blocks/header/icons/menu-close.svg)
        center center/cover no-repeat;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.menu__nav {
    margin-top: 30px;
}
.menu__list {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px;
}
.menu__left--start .menu__item a {
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.menu__list-name {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}
.menu__item-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    text-transform: uppercase;
    position: relative;
}
.menu__item-link::after,
.menu__item-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.menu__item-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.menu__item-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .menu__item-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .menu__item-link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.menu__item-link::after {
    background-color: rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
    .menu__item-link:hover::after {
        background-color: #3e3e3e;
    }
}
.menu__call {
    position: relative;
    margin-top: 72px;
    padding-left: 65px;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    color: #11255f;
}
.menu__call::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 35px;
    background: url(../../public/img/blocks/header/icons/call.svg)
        center center/cover no-repeat;
}
.menu__agatech-wrapper {
    display: flex;
    align-items: center;
    -moz-column-gap: 30px;
    column-gap: 30px;
    margin-top: auto;
}
.menu__agatech-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #11255f;
}
.menu__agatech-logo {
    max-width: 97px;
    width: 100%;
    max-height: 30px;
    -o-object-fit: cover;
    object-fit: cover;
}
@media (max-width: 1599px) {
    .header_active {
        max-width: 600px;
    }
    .header__activity-wrapper {
        display: none !important;
    }
    .header__logo-wrapper {
        padding-top: 15px;
        height: 10vh;
        height: calc(var(--vh, 1vh) * 10);
    }
    .header__info {
        height: 54vh;
        height: calc(var(--vh, 1vh) * 54);
    }
    .menu {
        max-width: 600px;
    }
    .menu__logo {
        max-width: 211px;
        max-height: 126px;
    }
    .menu__logo-wrapper {
        padding: 25px 40px 30px 40px;
    }
    .menu__info {
        padding: 40px;
        height: calc(100vh - 188px);
        height: calc(var(--vh, 1vh) * 100 - 188px);
    }
    .menu__nav {
        margin-top: 40px;
    }
    .menu__call {
        margin-top: 40px;
        padding-left: 40px;
        font-size: 26px;
        line-height: 30px;
    }
    .menu__call::before {
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 1439px) {
    .header_active {
        max-width: 500px;
    }
    .header__container {
        max-width: 250px;
    }
    .header__logo-wrapper {
        padding: 15px 55px 15px 30px;
        height: 10vh;
        height: calc(var(--vh, 1vh) * 10);
    }
    .header__activity-wrapper {
        padding: 3px 20px 3px 30px;
    }
    .header__info {
        padding: 30px 30px 35px 30px;
        height: 57vh;
        height: calc(var(--vh, 1vh) * 57);
    }
    .header__nav {
        margin-top: 15px;
    }
    .menu {
        max-width: 800px;
        width: 800px;
    }
    .menu__logo {
        max-width: 211px;
        max-height: 126px;
    }
    .menu__logo-wrapper {
        padding: 20px 30px 25px 30px;
    }
    .menu__info {
        padding: 40px 30px;
        height: calc(100vh - 178px);
        height: calc(var(--vh, 1vh) * 100 - 178px);
    }
    .menu__nav {
        margin-top: 40px;
    }
    .menu__item-link {
        font-size: 18px;
        line-height: 22px;
    }
    .menu__call {
        margin-top: 40px;
        padding-left: 40px;
        font-size: 18px;
        line-height: 25px;
    }
    .menu__call::before {
        width: 25px;
        height: 25px;
    }
}
@media (max-width: 991px) {
    .header .menu__list {
        gap: 10px;
    }
    .header .menu__list .menu__item-link {
        font-size: 14px;
        margin-bottom: 0;
    }
    .header_1920 {
        display: none;
    }
    .header_991 {
        display: flex;
    }
    .header__logo-link {
        margin-left: 29px;
    }
    .header__personal {
        margin-top: 0;
    }
    .header__flex {
        -moz-column-gap: 50px;
        column-gap: 50px;
    }
    .menu {
        max-width: unset;
        width: 100%;
        position: absolute;
        transform: translateX(0);
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
    }
    .menu_active {
        transition: opacity 0.2s;
        transform: translateX(0);
        opacity: 1;
        pointer-events: unset;
    }
    .menu__info {
        padding: 20px;
    }
    .menu__close {
        width: 35px;
        height: 35px;
    }
    .menu__logo {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }
    .menu__logo-wrapper {
        display: grid;
        grid-template-columns: minmax(auto, 15%) minmax(
                auto,
                70%
            ) minmax(auto, 15%);
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 7px 20px;
        height: 90px;
    }
    .menu__logo-link {
        justify-self: center;
        max-width: 123.65px;
        max-height: 79.16px;
    }
    .menu__call {
        margin-top: 0;
        padding-left: 30px;
        font-size: 16px;
        line-height: 22px;
    }
    .menu__call_991 {
        display: inline-block;
    }
    .menu__call_575 {
        display: none;
    }
    .menu__call::before {
        width: 20px;
        height: 20px;
    }
    .menu__nav {
        margin-top: 30px;
    }
    .menu__item-link {
        font-size: 16px;
        line-height: 19px;
    }
}
@media (max-width: 575px) {
    .header {
        padding: 10px;
    }
    .menu__logo-wrapper {
        padding: 10px;
    }
    .menu__info {
        padding: 10px;
        max-height: calc(100vh - 90px);
        max-height: calc(var(--vh, 1vh) * 100 - 90px);
        height: 100%;
        overflow-y: auto;
    }
    .menu__info::-webkit-scrollbar {
        width: 5px;
    }
    .menu__info::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
    }
    .menu__info::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
    }
    .menu__nav {
        margin-top: 20px;
    }
    .menu__call {
        margin-top: 35px;
    }
    .menu__call_991 {
        display: none;
    }
    .menu__call_575 {
        display: inline-block;
    }
}
.callback {
    padding: 25px 20px 25px 60px;
    width: 100%;
    height: 36vh;
    height: calc(var(--vh, 1vh) * 36);
    background-color: #071b55;
    overflow-y: auto;
}
.callback::-webkit-scrollbar {
    width: 5px;
}
.callback::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}
.callback::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
.callback__wrapper {
    height: 154px;
}
.callback__title {
    text-transform: uppercase;
    color: #fff;
}
.callback__title_inactive {
    display: none;
}
.callback__form {
    margin-top: 15px;
}
.callback__form_inactive {
    display: none;
}
.callback__form-container {
    position: relative;
}
.callback__form-input {
    padding: 10px 30px 10px 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 1px solid #fff;
}
.callback__form-input::-moz-placeholder {
    color: #a9a9a9;
}
.callback__form-input::placeholder {
    color: #a9a9a9;
}
.callback__form-input:focus {
    width: calc(100% - 20px);
    margin-left: -10px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 40px;
    outline: 0;
    border: 1px solid #fff;
    border-radius: 5px;
}
.callback__form-submit {
    position: absolute;
    top: 13px;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.callback__form-arrow path {
    transition: fill 0.2s;
    fill: rgba(255, 255, 255, 0.5);
}
@media (min-width: 992px) {
    .callback__form-arrow:hover path {
        fill: #fff;
    }
}
.callback__form-label {
    display: flex;
    cursor: pointer;
    transition: opacity 0.1s;
}
@media (min-width: 992px) {
    .callback__form-label:active {
        opacity: 0.5;
    }
}
.callback__form-checkbox-real:checked
    + .callback__form-checkbox-fake {
    border: 1px solid #fff;
}
.callback__form-checkbox-real:checked
    + .callback__form-checkbox-fake::before {
    content: "";
    position: absolute;
    top: 48.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 10px;
    background: url(../../public/img/blocks/callback/icons/check.svg)
        center center/cover no-repeat;
}
.callback__form-checkbox-fake {
    position: relative;
    margin-top: 13px;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #a9a9a9;
}
.callback__form-checkbox-text {
    margin: 10px 0 0 7px;
    max-width: 89%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #a9a9a9;
}
.callback--vk:hover path {
    fill: #07f;
    stroke: #07f;
}
.callback--viber:hover path {
    fill: #7360f2;
    stroke: #7360f2;
}
.callback--wa:hover path {
    fill: #25d366;
    stroke: #25d366;
}
.callback--tg:hover path {
    fill: #08c;
    stroke: #08c;
}
.callback__social {
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
    margin-top: 5px;
}
.callback__social-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 992px) {
    .callback__social-link:hover {
        transform: scale(1.05);
    }
    .callback__social-link:hover .callback__social-icon {
        transform: scale(1.05);
    }
    .callback__social-link:active .callback__social-icon {
        opacity: 0.5;
    }
}
.callback__social-icon {
    transition: transform 0.2s;
}
.callback__message {
    display: none;
    text-transform: uppercase;
    color: #fff;
}
.callback__message_active {
    display: inline-block;
}
.callback__contacts {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 10px;
    margin-top: 10px;
}
.callback__mail,
.callback__phone {
    color: #fff;
    position: relative;
}
.callback__mail::after,
.callback__mail::before,
.callback__phone::after,
.callback__phone::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.callback__mail::before,
.callback__phone::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.callback__mail::after,
.callback__phone::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .callback__mail:hover::after,
    .callback__phone:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .callback__mail:active,
    .callback__phone:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.callback__privacy {
    display: inline;
    font-size: inherit;
    line-height: inherit;
}
@media (min-width: 992px) {
    .callback__privacy:hover {
        text-decoration: underline;
    }
    .callback__privacy:active {
        opacity: 0.5;
    }
}
@media (max-width: 1439px) {
    .callback {
        padding: 15px 20px 15px 30px;
        height: 37vh;
        height: calc(var(--vh, 1vh) * 37);
    }
    .callback__wrapper {
        height: auto;
    }
    .callback__title {
        display: none;
    }
    .callback__form {
        margin-top: 0;
    }
    .callback__social {
        margin-top: 15px;
    }
    .callback__mail {
        display: none;
    }
}
@media (max-width: 991px) {
    .callback__contacts {
        display: none;
    }
}
.spinner {
    width: 50px;
    height: 50px;
    border: 8px solid #fff;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spinneranim 2s infinite linear;
    animation: spinneranim 2s infinite linear;
}
.spinner-wrapper {
    display: none;
}
.spinner-wrapper_active {
    display: block;
}
.spinner::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #11255f;
    position: absolute;
    top: -7px;
    left: 14px;
}
@-webkit-keyframes spinneranim {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spinneranim {
    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 1439px) {
    .spinner {
        width: 40px;
        height: 40px;
        border: 6px solid #fff;
    }
    .spinner::before {
        top: -6px;
    }
}
.footer {
    display: none;
}
@media (max-width: 991px) {
    .footer {
        display: flex;
    }
    .footer__info {
        padding: 50px 20px 25px 40px;
        width: 100%;
        background-color: #11255f;
    }
    .footer__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        row-gap: 10px;
    }
    .footer__list-link {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
    }
    .footer__call {
        position: relative;
        margin-top: 20px;
        padding-left: 52px;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        color: #fff;
    }
    .footer__call::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 26px;
        height: 26px;
        background: url(../../public/img/blocks/footer/icons/call.svg)
            center center/cover no-repeat;
    }
    .footer__agatech-wrapper {
        display: flex;
        align-items: center;
        -moz-column-gap: 38px;
        column-gap: 38px;
        margin-top: 50px;
    }
    .footer__agatech-text {
        font-weight: 300;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #a9a9a9;
    }
    .footer__agatech-logo {
        max-width: 97px;
        width: 100%;
        max-height: 30px;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .callback {
        padding: 50px 20px;
        max-width: 260px;
        height: auto;
    }
}
@media (max-width: 767px) {
    .footer {
        flex-wrap: wrap;
    }
    .footer__info {
        padding: 40px 20px 40px 20px;
    }
    .footer__call {
        margin-top: 50px;
    }
    .callback {
        padding: 40px 20px;
        max-width: unset;
    }
    .callback__form {
        max-width: 300px;
    }
}
@media (max-width: 575px) {
    .footer__info {
        padding: 40px 10px 20px 10px;
    }
    .footer__list {
        grid-template-columns: repeat(2, auto);
    }
    .footer__agatech-wrapper {
        justify-content: space-between;
        -moz-column-gap: 0;
        column-gap: 0;
    }
    .callback {
        padding: 25px 10px;
    }
}
.breadcrumbs {
    padding: 50px 200px 0 100px;
    background-color: #fff;
}
.breadcrumbs__item {
    display: inline;
    color: #a9a9a9;
}
.breadcrumbs__item:not(:first-child) {
    margin-left: 5px;
}
.breadcrumbs__item:not(:first-child)::before {
    display: inline-block;
    content: "";
    margin-right: 5px;
    width: 9px;
    height: 15px;
    background: url(../../public/img/blocks/breadcrumbs/icons/breadcrumbs-arrow.svg)
        center center/cover no-repeat;
}
.breadcrumbs__current,
.breadcrumbs__link {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
}
.breadcrumbs__link {
    position: relative;
}
.breadcrumbs__link::after,
.breadcrumbs__link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.breadcrumbs__link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.breadcrumbs__link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .breadcrumbs__link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .breadcrumbs__link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.breadcrumbs__link:after {
    background-color: #a9a9a9;
}
@media (min-width: 992px) {
    .breadcrumbs__link:hover::after {
        background-color: #a9a9a9;
    }
}
.breadcrumbs__current {
    display: inline;
    color: #3e3e3e;
    word-break: break-all;
}
.breadcrumbs__current_white {
    color: #fff;
}
@media (max-width: 1919px) {
    .breadcrumbs {
        padding: 50px 100px 0 70px;
    }
}
@media (max-width: 1599px) {
    .breadcrumbs {
        padding: 50px 70px 0 50px;
    }
}
@media (max-width: 1439px) {
    .breadcrumbs {
        padding: 30px 50px 0 30px;
    }
}
@media (max-width: 991px) {
    .breadcrumbs {
        padding: 30px 30px 0 30px;
    }
}
@media (max-width: 767px) {
    .breadcrumbs {
        padding: 30px 20px 0 20px;
    }
}
@media (max-width: 575px) {
    .breadcrumbs {
        padding: 30px 10px 0 10px;
    }
    .breadcrumbs__wrapper {
        display: block;
    }
    .breadcrumbs__arrow {
        margin-left: 5px;
    }
    .breadcrumbs__text {
        word-break: break-all;
    }
    .breadcrumbs__text_current {
        margin-left: 5px;
    }
}
.description {
    display: flex;
}
.description .breadcrumbs {
    padding: 0;
    background-color: #071b55;
}
.description .breadcrumbs__text_current {
    color: #fff;
}
.description__wrapper {
    padding: 50px 50px 50px 100px;
    max-width: 44%;
    width: 100%;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: #071b55;
    display: flex;
    flex-direction: column;
}
.description__title {
    margin-top: 10px;
    color: #fff;
    font-size: 35px;
}
.description__picture {
    display: flex;
}
.description__img {
    max-width: 100%;
    width: 100%;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    -o-object-fit: cover;
    object-fit: cover;
}
.description__info {
    margin-top: 50px;
    padding-right: 10px;
    width: 100%;
    max-height: 58vh;
    max-height: calc(var(--vh, 1vh) * 58);
    color: #fff;
    overflow-y: auto;
}
.description__info::-webkit-scrollbar {
    width: 5px;
}
.description__info::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}
.description__info::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
}
.description__info-text {
    min-height: 150px;
    max-height: 150px;
}
.description__info-text:not(:first-child) {
    margin-top: 15px;
}
.description__info-list {
    margin-top: 20px;
    padding-left: 20px;
    list-style: auto;
}
.description__more {
    margin-top: 50px;
    width: 250px;
    position: relative;
    padding: 16px 37px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid currentColor;
    overflow: hidden;
    transition: color 0.5s;
}
.description__more::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .description__more:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .description__more:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.description__more span {
    position: relative;
    z-index: 1;
}
.description__more_menu {
    color: #11255f;
    background-color: #fff;
}
.description__more_menu::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .description__more_menu:hover {
        color: #fff;
        border: 1px solid #11255f;
    }
}
@media (min-width: 992px) {
    .description__more:hover::before {
        width: 450px;
        height: 450px;
    }
}
.description__gallery {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    margin-top: auto;
}
.description__gallery-item {
    position: relative;
    flex-basis: 48%;
}
.description__gallery-link {
    min-width: 100%;
}
.description__gallery-link:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 5px);
    background: rgba(0, 0, 0, 0.5);
    transition: 0.5s;
}
.description__gallery-link:hover:before {
    opacity: 0;
}
.description__gallery-image {
    max-height: calc(135px + 45 * (100vw - 1024px) / 896);
    min-height: calc(135px + 45 * (100vw - 1024px) / 896);
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
@media (max-width: 1919px) {
    .description__wrapper {
        padding-left: 50px;
    }
}
@media (max-width: 1599px) {
    .description__wrapper {
        padding-left: 30px;
    }
}
@media (max-width: 1439px) {
    .description__wrapper {
        padding: 30px 25px 30px 30px;
        max-width: 53%;
    }
    .description__img {
        -o-object-position: 80% 50%;
        object-position: 80% 50%;
    }
    .description__info {
        margin-top: 30px;
    }
}
@media (max-width: 1199px) {
    .description__wrapper {
        max-width: 60%;
    }
}
@media (max-width: 991px) {
    .description {
        flex-wrap: wrap;
    }
    .description__wrapper {
        max-width: 100%;
        max-height: unset;
        height: auto;
        padding-top: 40px  !important;
    }
    .description__img {
        height: auto;
    }
    .description__info {
        max-height: unset;
    }
    .description__info-text {
        max-height: 100%;
        min-height: 100%;
    }
    .description__gallery {
        display: none;
    }
    .description .breadcrumbs {
        padding: 70px 20px 20px 20px;
        width: 100%;
        background-color: #071b55;
    }
}
@media (max-width: 767px) {
    .description__wrapper {
        padding: 20px 20px 50px 20px;
    }
    .description__more {
        width: 100%;
    }
}
@media (max-width: 575px) {
    .description__wrapper {
        padding: 20px 10px 30px 10px;
    }
    .description__title {
        line-height: 40px;
    }
}
.stages__list {
    margin-top: 50px;
    display: grid;
    grid-template-columns: minmax(auto, 335px) minmax(
            auto,
            460px
        ) minmax(auto, 400px);
    -moz-column-gap: 70px;
    column-gap: 70px;
    row-gap: 70px;
}
.stages__item {
    padding-left: 10px;
    border-left: 1px solid #a9a9a9;
}
.stages__item-number {
    font-weight: 300;
    font-size: 96px;
    line-height: 70px;
    text-align: center;
    color: #11255f;
}
.stages__item-title {
    margin-top: 30px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #11255f;
}
.stages__item-descr {
    margin-top: 10px;
    padding-bottom: 5px;
    max-width: 405px;
    width: 100%;
}
.stages__item-link {
    margin-top: 5px;
    font-weight: 700;
    text-decoration: underline;
}
@media (max-width: 1919px) {
    .stages__list {
        -moz-column-gap: 70px;
        column-gap: 70px;
    }
}
@media (max-width: 1599px) {
    .stages__list {
        -moz-column-gap: 100px;
        column-gap: 100px;
    }
}

@media (max-width: 767px) {
    .stages__list {
        gap: 30px;
    }
}
@media (max-width: 575px) {
    .stages__list {
        grid-template-columns: 1fr;
    }
}
.subscribe {
    padding-top: 75px;
}
.subscribe__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.subscribe__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 540px;
}
.subscribe__list_left {
    grid-column: 1/2;
}
.subscribe__list_right {
    grid-column: 3/4;
}
.subscribe__text {
    position: relative;
    padding: 0 15px;
    max-width: 390px;
    width: 100%;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
}
.subscribe__text::before {
    content: "";
    position: absolute;
    top: -68px;
    left: 50%;
    transform: translateX(-50%);
    width: 68px;
    height: 40px;
    background: url(../../public/img/blocks/subscribe/icons/vk.svg)
        center center/cover no-repeat;
}
.subscribe__text-wrapper {
    display: flex;
    grid-column: 2/3;
    justify-content: center;
    align-items: center;
}
.subscribe__link {
    position: relative;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    background: linear-gradient(
        95.61deg,
        #ff00c7 0,
        #7000ff 98.87%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    word-break: break-all;
}
.subscribe__link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
        95.61deg,
        #ff00c7 0,
        #7000ff 98.87%
    );
}
@media (min-width: 992px) {
    .subscribe__link:hover::after {
        display: none;
    }
}
.subscribe__item {
    max-width: 270px;
    width: 100%;
    max-height: 270px;
}
.subscribe__item-img {
    display: flex;
    max-width: 100%;
    max-height: 100%;
}
@media (max-width: 1599px) {
    .subscribe {
        padding-top: 60px;
    }
}
@media (max-width: 1439px) {
    .subscribe {
        padding-top: 50px;
    }
    .subscribe__text {
        font-size: 33px;
        line-height: 49px;
    }
}
@media (max-width: 1199px) {
    .subscribe__wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
    .subscribe__list {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-auto-rows: 0;
    }
    .subscribe__list_left .subscribe__item:nth-child(1) {
        order: 3;
    }
    .subscribe__list_left .subscribe__item:nth-child(2) {
        order: 1;
    }
    .subscribe__list_left .subscribe__item:nth-child(3) {
        order: 4;
    }
    .subscribe__list_left .subscribe__item:nth-child(4) {
        order: 2;
    }
    .subscribe__list_right {
        grid-column: 4/5;
    }
    .subscribe__list_right .subscribe__item:nth-child(1) {
        order: 1;
    }
    .subscribe__list_right .subscribe__item:nth-child(2) {
        order: 3;
    }
    .subscribe__list_right .subscribe__item:nth-child(3) {
        order: 2;
    }
    .subscribe__list_right .subscribe__item:nth-child(4) {
        order: 4;
    }
    .subscribe__text-wrapper {
        grid-column: 2/4;
    }
}
@media (max-width: 639px) {
    .subscribe {
        padding: 100px 0 80px 0;
    }
    .subscribe__wrapper {
        grid-template-columns: 1fr;
    }
    .subscribe__list {
        display: none;
    }
    .subscribe__text {
        padding: 0 5px;
    }
    .subscribe__text-wrapper {
        grid-column: auto;
    }
}
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    transition-duration: 0.4s;
    pointer-events: none;
}
.modal_active {
    opacity: 1;
    pointer-events: unset;
}
.modal_active .modal__container {
    transform: translateY(0);
    opacity: 1;
}
.modal__container {
    position: relative;
    transform: translateY(-500px);
    max-width: 900px;
    width: 100%;
    min-height: 600px;
    max-height: 80vh;
    opacity: 0;
    transition-duration: 0.4s;
}
.modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 40px;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
}
.modal__message,
.modal__text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}
.modal__message {
    display: none;
    text-align: center;
}
.modal__message_active {
    display: block;
    transform: translateX(130px);
}
.modal__form_inactive {
    display: none;
}
.modal__form-input {
    padding: 13px 15px;
    width: 100%;
    color: #fff;
    background-color: #11255f;
    border: 1px solid #fff;
}
.modal__form-input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
}
.modal__form-input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}
.modal__form-submit {
    display: block;
    margin-top: 20px;
    width: 380px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #11255f;
}
.modal__user {
    display: flex;
    justify-content: space-between;
    -moz-column-gap: 20px;
    column-gap: 20px;
}
.modal__user-name,
.modal__user-phone,
.modal__user-surname {
    max-width: 50%;
    width: 100%;
}
.modal__theme {
    margin-top: 20px;
}
.modal__textarea {
    margin-top: 20px;
    min-height: 175px;
    max-height: 350px;
    resize: vertical;
}
.more__modal .modal__dev {
    padding: 17px 45px 17px 17px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
}
.more__modal .modal__close {
    color: #11255f;
}
.more__modal .modal__info {
    padding: 50px 40px;
    width: 100%;
    min-height: 530px;
    background-color: #11255f;
    overflow: auto;
}
.more__modal .modal__info-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #fff;
}
.more__modal .modal__list {
    margin-top: 20px;
}
.more__modal .modal__item-text {
    color: #fff;
}
.more__modal .modal__item-link {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    position: relative;
}
.more__modal .modal__item-link::after,
.more__modal .modal__item-link::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 2px;
}
.more__modal .modal__item-link::before {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}
.more__modal .modal__item-link::after {
    width: 0;
    background-color: #fff;
}
@media (min-width: 992px) {
    .more__modal .modal__item-link:hover::after {
        -webkit-animation: adjustWidth 0.4s forwards;
        animation: adjustWidth 0.4s forwards;
    }
    .more__modal .modal__item-link:active {
        opacity: 0.5;
    }
}
@keyframes adjustWidth {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.more__modal .modal__contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 40px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.more__modal .modal__contacts-item {
    padding-left: 10px;
    border-left: 1px solid #fff;
}
.more__modal .modal__contacts-text {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
}
.review__modal .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 50px 69px 50px;
    background-color: #11255f;
}
.review__modal .modal__close {
    color: #fff;
}
.review__modal .modal__text {
    align-self: flex-start;
}
.review__modal .modal__text_inactive {
    display: none;
}
.review__modal .modal__form {
    margin-top: 30px;
    width: 100%;
}
.form__modal .modal__form #inputOutput {
    position: absolute;
    right: 0;
    top: 0;
    padding: 50px 10px 50px 10px;
    min-width: 300px;
    background: #071b55;
    height: 100%;
}
.form__modal .modal__form #inputOutput .inputOutput__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #fff;
    text-align: center;
}
.form__modal .modal__form #inputOutput .inputOutput__img {
    margin-bottom: 20px;
}
.form__modal .modal__form #inputOutput .dateOutput {
    margin-bottom: 10px;
}
.form__modal
    .modal__form
    #inputOutput
    .inputOutput__wrapper {
    margin-bottom: 10px;
    color: #fff;
}
.form__modal
    .modal__form
    #inputOutput
    .inputOutput__wrapper
    p {
    margin-bottom: 15px;
}
.form__modal .modal__form #inputOutput .checkboxOutput {
    display: none;
    position: relative;
    padding-left: 30px;
}
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
}
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--bengaL.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--colored.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--firE.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--pyro.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--salyT.visible,
.form__modal
    .modal__form
    #inputOutput
    .checkboxOutput--smokE.visible {
    display: block;
    color: #fff;
}
.form__modal .modal__form .modal__form-input:focus-visible {
    outline: 0;
    border: 1px solid #fff;
}
.form__modal .modal__form .modal__form-input {
    border-bottom: 1px solid #fff;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
}
.form__modal .modal__form .modal__contact-info {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.form__modal .modal__form .modal__contact-info-left {
    flex-basis: 35%;
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-right {
    flex-basis: 50%;
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-address,
.form__modal .modal__form .modal__contact-info-mail,
.form__modal .modal__form .modal__contact-info-tel {
    color: #fff;
}
.form__modal .modal__form .modal__contact-info-tel--mb {
    margin-bottom: 20px;
}
.form__modal .modal__form .modal__contact-info-address {
    white-space: nowrap;
    margin-bottom: 15px;
}
.form__modal .modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 50px 20px 55px;
    min-height: 650px;
    background: url(../../public/img/blocks/modal/bg.jpg);
    min-width: 1000px;
    padding-right: 315px;
}
.form__modal .modal__close {
    color: #fff;
    z-index: 5;
}
.form__modal .modal__form {
    max-width: 100%;
    height: 540px;
}
.form__modal .modal__date {
    margin: 0;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: #fff;
    font-size: 47px;
    line-height: 55px;
}
.form__modal .modal__date:focus-visible {
    outline: 0;
    border: none;
}
.form__modal .modal__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 20px;
    column-gap: 20px;
    width: 100%;
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.form__modal .modal__step {
    color: #fff;
}
.form__modal .modal__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 20px;
    column-gap: 20px;
    row-gap: 40px;
    margin-top: 40px;
}
.form__modal .modal__item {
    cursor: pointer;
}
.form__modal .modal__item-picture {
    display: flex;
}
.form__modal .modal__item-img {
    max-width: 200px;
    width: 100%;
    max-height: 130px;
    -o-object-fit: cover;
    object-fit: cover;
}
.form__modal .modal__item-wrapper {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 4px 10px;
    background-color: rgba(255, 255, 255, 0.5);
}
.form__modal .modal__item-title {
    font-weight: 400;
    color: #fff;
}
.form__modal
    .modal__checkbox-real:checked
    + .modal__checkbox-fake {
    background-color: #fff;
}
.form__modal .modal__checkbox-fake {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 50%;
}
.form__modal .modal__user-name {
    margin-top: 40px;
}
.form__modal .modal__user-phone {
    margin-top: 20px;
}
.form__modal .swiper {
    height: 100%;
}
.form__modal .swiper-container {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.form__modal .swiper-container .modal__output {
    min-width: 240px;
    padding: 20px;
    color: #fff;
}
.form__modal .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.form__modal .modal__submit,
.form__modal .swiper-button-back,
.form__modal .swiper-button-next {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #fff;
    overflow: hidden;
    transition: color 0.5s;
    top: unset;
    left: unset;
    right: unset;
    margin: 0;
    padding: 16px 20px;
    width: auto;
    height: auto;
    opacity: 0.35;
    cursor: not-allowed;
    pointer-events: none;
}
.form__modal .modal__submit::before,
.form__modal .swiper-button-back::before,
.form__modal .swiper-button-next::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover,
    .form__modal .swiper-button-back:hover,
    .form__modal .swiper-button-next:hover {
        color: #11255f;
        border: 1px solid #fff;
    }
    .form__modal .modal__submit:hover::before,
    .form__modal .swiper-button-back:hover::before,
    .form__modal .swiper-button-next:hover::before {
        width: 300px;
        height: 300px;
        transition: width 0.7s, height 0.7s;
    }
}
.form__modal .modal__submit span,
.form__modal .swiper-button-back span,
.form__modal .swiper-button-next span {
    position: relative;
    z-index: 1;
}
.form__modal .modal__submit::before,
.form__modal .swiper-button-back::before,
.form__modal .swiper-button-next::before {
    background-color: #11255f;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover,
    .form__modal .swiper-button-back:hover,
    .form__modal .swiper-button-next:hover {
        color: #fff;
        border: 1px solid #fff;
    }
}
.form__modal .modal__submit_enabled,
.form__modal .swiper-button-back_enabled,
.form__modal .swiper-button-next_enabled {
    opacity: 1 !important;
    cursor: pointer !important;
    pointer-events: auto !important;
}
@media (min-width: 992px) {
    .form__modal .modal__submit:hover::after,
    .form__modal .swiper-button-back:hover::after,
    .form__modal .swiper-button-next:hover::after {
        color: #fff;
    }
}
.form__modal .modal__submit::after,
.form__modal .swiper-button-back::after,
.form__modal .swiper-button-next::after {
    padding-left: 20px;
    font-size: 14px;
    z-index: 1;
}
::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url(../../public/img/blocks/modal/date.png)
        no-repeat center;
    background-size: contain;
}
#ui-datepicker-div {
    z-index: 10 !important;
}
input[type="date"] {
    position: relative;
    padding: 4px 0 0 25px !important;
    outline: 0;
    width: 62%;
    margin-top: 0 !important;
    background: rgba(0, 0, 0, 0);
    border: rgba(0, 0, 0, 0);
    color: #fff;
    font-size: 47px;
    box-sizing: border-box;
    line-height: 55px;
    text-align: center;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: rgba(0, 0, 0, 0);
    background: rgba(0, 0, 0, 0);
}
input[type="date"]::-webkit-clear-button {
    z-index: -10;
}
input[type="date"]::-webkit-input-placeholder {
    z-index: 99;
    white-space: nowrap;
}
input[type="date"]:not(.has-value):before {
    content: attr(placeholder);
    width: 100%;
}
input[type="date"] {
    padding: 12px 0;
}
.date-time-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.date-time-wrapper img {
    top: 50%;
    left: 95px;
    position: absolute;
    transform: translateY(-50%);
    width: 47px;
    height: 47px;
}
@media (max-width: 575px) {
    input[type="date"] {
        font-size: 24px !important;
    }
    .date-time-wrapper img {
        left: 45px;
    }
}
@media (max-width: 991px) {
    .modal__container {
        max-width: 90vw;
    }
    .review__modal .modal__container {
        padding: 70px 30px 50px 30px;
    }
    .review__modal .modal__form-submit {
        width: 100%;
    }
    .form__modal .modal__list {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .modal__container {
        max-width: 100vw;
        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    .more__modal .modal__info {
        height: 100%;
    }
    .more__modal .modal__contacts {
        grid-template-columns: repeat(2, 1fr);
    }
    .review__modal .modal__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form__modal #inputOutput {
        display: none;
    }
    .form__modal .modal__contact-info {
        display: none !important;
    }
    .form__modal .modal__user-phone + .modal__text {
        display: none;
    }
    .form__modal .modal__message_active {
        transform: translateX(0);
    }
    .form__modal .modal__item-wrapper {
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 5px;
    }
    .form__modal .modal__date {
        line-height: 24px;
        font-size: 24px;
    }
    .form__modal .modal__container {
        min-width: 320px;
        max-width: 320px;
        padding: 20px;
    }
    .form__modal .modal__form {
        height: 470px;
    }
    .form__modal .modal__item-wrapper {
        padding: 4px 9px;
    }
    .form__modal .modal__item-title {
        font-size: 12px;
        line-height: 20px;
    }
    .form__modal .modal__user-name,
    .form__modal .modal__user-phone {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .more__modal .modal__dev {
        font-size: 18px;
        line-height: 27px;
    }
    .more__modal .modal__info {
        padding: 20px;
    }
    .more__modal .modal__contacts {
        grid-template-columns: 1fr;
    }
    .review__modal .modal__container {
        padding: 70px 10px 50px 10px;
    }
    .review__modal .modal__user {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .review__modal .modal__user-name,
    .review__modal .modal__user-surname {
        max-width: 100%;
    }
    .form__modal .modal__container {
        min-width: 100% !important;
        padding: 50px 10px;
    }
    .form__modal .modal__item-picture {
        display: none;
    }
    .form__modal .modal__list {
        grid-template-columns: 1fr;
        row-gap: 15px;
        width: 100%;
    }
    .form__modal .modal__footer {
        -moz-column-gap: 10px;
        column-gap: 10px;
    }
    .form__modal .modal__step {
        font-size: 11px;
        line-height: 15px;
    }
}
