
.advantages__list {
    margin-top: 50px;
    width: 100%
}

.advantages__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid #a9a9a9
}

.advantages__item:first-child {
    border-top: 1px solid #a9a9a9
}

.advantages__item:nth-child(even) .advantages__descr {
    order: 2;
    text-align: left
}

.advantages__item:nth-child(even) .advantages__img {
    order: 1
}

.advantages__item_safety {
    -moz-column-gap: 150px;
    column-gap: 150px
}

.advantages__item_safety .advantages__descr {
    max-width: 595px
}

.advantages__item_safety .advantages__img {
    max-width: 300px
}

.advantages__item_variety {
    -moz-column-gap: 50px;
    column-gap: 50px
}

.advantages__item_variety .advantages__descr {
    max-width: 590px
}

.advantages__item_variety .advantages__img {
    max-width: 300px
}

.advantages__item_duration {
    -moz-column-gap: 100px;
    column-gap: 100px
}

.advantages__item_duration .advantages__descr {
    max-width: 360px
}

.advantages__item_duration .advantages__img {
    max-width: 270px
}

.advantages__item_height {
    -moz-column-gap: 150px;
    column-gap: 150px
}

.advantages__item_height .advantages__descr {
    max-width: 375px
}

.advantages__item_height .advantages__img {
    max-width: 250px
}

.advantages__item_resistance {
    -moz-column-gap: 50px;
    column-gap: 50px
}

.advantages__item_resistance .advantages__descr {
    max-width: 435px
}

.advantages__item_resistance .advantages__img {
    max-width: 350px
}

.advantages__descr {
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: right
}

.advantages__img {
    display: flex;
    width: 100%;
    max-height: 150px;
    -o-object-fit: cover;
    object-fit: cover
}

.spinners__descr {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 540px));
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
    -moz-column-gap: 50px;
    column-gap: 50px;
    margin-top: 50px
}

.spinners__text_left {
    grid-column: 1/2;
    grid-row: 1/3
}

.spinners__text_right {
    grid-column: 2/3;
    grid-row: 1/2
}

.spinners__text_bottom {
    margin-top: 30px;
    max-width: 700px
}

.spinners__link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 2/3;
    grid-row: 2/3;
    margin-top: 20px;
    width: 230px;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #11255f;
    background-color: #fff;
    border: 1px solid #11255f;
    overflow: hidden;
    transition: color .5s
}

.spinners__link::before {
    content: "";
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: width .5s, height .5s
}

@media(min-width:992px) {
    .spinners__link:hover {
        color: #11255f;
        border: 1px solid #fff
    }

    .spinners__link:hover::before {
        width: 300px;
        height: 300px;
        transition: width .7s, height .7s
    }
}

.spinners__link span {
    position: relative;
    z-index: 1
}

.spinners__link::before {
    background-color: #11255f;
    transition: width .6s, height .6s
}

@media(min-width:992px) {
    .spinners__link:hover {
        color: #fff;
        border: 1px solid #11255f
    }

    .spinners__link:hover::before {
        width: 500px;
        height: 500px
    }
}

.spinners__link_576 {
    display: none
}

.spinners__img {
    margin-top: 30px;
    width: 100%;
    max-height: 600px;
    -o-object-fit: cover;
    object-fit: cover
}

@media(max-width:1439px) {
    .advantages__item {
        padding: 20px 10px
    }

    .advantages__item_safety {
        justify-content: flex-end;
        -moz-column-gap: 110px;
        column-gap: 110px
    }

    .advantages__item_safety .advantages__descr {
        max-width: 430px
    }

    .advantages__item_safety .advantages__img {
        max-width: 250px
    }

    .advantages__item_variety {
        justify-content: flex-start
    }

    .advantages__item_variety .advantages__descr {
        max-width: 475px
    }

    .advantages__item_duration {
        justify-content: flex-end;
        -moz-column-gap: 90px;
        column-gap: 90px
    }

    .advantages__item_height {
        justify-content: flex-start;
        -moz-column-gap: 100px;
        column-gap: 100px
    }

    .advantages__item_height .advantages__img {
        max-width: 250px
    }

    .advantages__item_resistance {
        justify-content: flex-end;
        -moz-column-gap: 30px;
        column-gap: 30px
    }

    .advantages__item_resistance .advantages__descr {
        max-width: 410px
    }
}

@media(max-width:1199px) {
    .advantages__item {
        -moz-column-gap: 20px;
        column-gap: 20px;
        padding: 20px 0
    }

    .advantages__item_safety .advantages__descr {
        max-width: 400px
    }

    .advantages__item_variety .advantages__descr {
        max-width: 335px
    }

    .advantages__item_resistance .advantages__descr {
        max-width: 300px
    }

    .spinners__descr {
        display: block;
        grid-template-columns: auto;
        grid-template-rows: auto;
        margin-top: 30px
    }

    .spinners__text {
        display: inline
    }

    .spinners__text_left,
    .spinners__text_right {
        grid-column: auto;
        grid-row: auto
    }

    .spinners__text_bottom {
        display: block;
        margin-top: 20px
    }

    .spinners__link {
        grid-column: auto;
        grid-row: auto
    }
}

@media(max-width:767px) {
    .advantages__list {
        margin-top: 40px
    }

    .advantages__item {
        display: block;
        padding: 10px 0 20px 0
    }

    .advantages__item_safety .advantages__descr {
        margin-left: auto;
        max-width: 460px;
        text-align: right
    }

    .advantages__item_safety .advantages__img {
        max-width: 300px
    }

    .advantages__item_variety .advantages__descr {
        max-width: 520px
    }

    .advantages__item_variety .advantages__img {
        margin-left: auto
    }

    .advantages__item_duration .advantages__descr {
        margin-left: auto;
        max-width: unset
    }

    .advantages__item_height .advantages__descr {
        max-width: unset
    }

    .advantages__item_height .advantages__img {
        margin-left: auto
    }

    .advantages__item_resistance .advantages__descr {
        margin-left: auto;
        max-width: 460px
    }

    .advantages__item_resistance .advantages__img {
        max-width: 300px
    }

    .advantages__descr {
        font-size: 14px;
        line-height: 20px
    }

    .advantages__img {
        margin-top: 10px
    }

    .spinners__link_1920 {
        display: none
    }

    .spinners__link_576 {
        display: flex;
        width: 100%
    }
}

@media(max-width:575px) {
    .advantages__item_safety .advantages__img {
        max-width: none;
        max-height: none;
    }

    .spinners__img {
        height: 300px
    }
    .advantages__item_variety .advantages__img {
        max-width: none;
        max-height: none;
    }
    .advantages__item_duration .advantages__img {
        max-width: none;
        max-height: none;
    }
    .advantages__item_height .advantages__img {
        max-width: none;
        max-height: none;
    }
    .advantages__item_resistance .advantages__img {
        max-width: none;
        max-height: none;
    }
}

@media(max-width:480px) {

    picture {
        display: flex;
    }

    .description {
        flex-wrap: wrap-reverse !important;
        margin-top: 90px;
    }
}