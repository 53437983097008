
.reviews {
	padding-right: 315px
}

.reviews__flex {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	-moz-column-gap: 50px;
	column-gap: 50px
}

.reviews__button {
	font-weight: 700;
	color: #11255f;
	position: relative
}

.reviews__button::after,
.reviews__button::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -1px;
	height: 2px
}

.reviews__button::before {
	width: 100%;
	background-color: rgba(255, 255, 255, .1)
}

.reviews__button::after {
	width: 0;
	background-color: #fff
}

@media(min-width:992px) {
	.reviews__button:hover::after {
		-webkit-animation: adjustWidth .4s forwards;
		animation: adjustWidth .4s forwards
	}

	.reviews__button:active {
		opacity: .5
	}
}

@keyframes adjustWidth {
	0% {
		width: 0%
	}

	100% {
		width: 100%
	}
}

.reviews__button:after {
	width: 100%;
	bottom: 0;
	background-color: #11255f
}

@media(min-width:992px) {
	.reviews__button:hover::after {
		background-color: #11255f;
		-webkit-animation: adjustWidth .6s forwards;
		animation: adjustWidth .6s forwards
	}
}

.reviews__list {
	margin-top: 50px;
	padding-left: 100px
}

.reviews__item {
	display: flex;
	justify-content: space-between;
	-moz-column-gap: 60px;
	column-gap: 60px;
	padding: 20px 0 30px 0;
	border-bottom: 1px solid #a9a9a9
}

.reviews__item:first-child {
	border-top: 1px solid #a9a9a9
}

.reviews__author {
	max-width: 180px;
	width: 100%
}

.reviews__name {
	display: inline-block;
	width: 100%;
	font-weight: 700;
	font-size: 24px;
	line-height: 34px
}

.reviews__date {
	margin-top: 10px;
	width: 100%;
	color: #a9a9a9
}

.reviews__content {
	width: 100%
}

.reviews__subtitle {
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
	text-transform: uppercase
}

.reviews__descr {
	margin-top: 15px
}

.reviews__more {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 50px auto 0 auto;
	width: 380px;
	height: 50px;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	color: #11255f;
	background-color: #fff;
	border: 1px solid #11255f;
	overflow: hidden;
	transition: color .5s
}

.reviews__more::before {
	content: "";
	position: absolute;
	top: var(--y);
	left: var(--x);
	transform: translate(-50%, -50%);
	width: 0;
	height: 0;
	background-color: #fff;
	border-radius: 50%;
	transition: width .5s, height .5s
}

@media(min-width:992px) {
	.reviews__more:hover {
		color: #11255f;
		border: 1px solid #fff
	}

	.reviews__more:hover::before {
		width: 300px;
		height: 300px;
		transition: width .7s, height .7s
	}
}

.reviews__more span {
	position: relative;
	z-index: 1
}

.reviews__more::before {
	background-color: #11255f;
	transition: width .6s, height .6s
}

@media(min-width:992px) {
	.reviews__more:hover {
		color: #fff;
		border: 1px solid #11255f
	}

	.reviews__more:hover::before {
		width: 1111px;
		height: 1111px
	}
}

@media(max-width:767px) {
	.reviews__more {
		width: 100%
	}
}

@media(max-width:575px) {
	.reviews__more {
		margin-top: 30px
	}
}

@media(max-width:1919px) {
	.reviews {
		padding-right: 200px
	}

	.reviews__list {
		padding-left: 50px
	}
}

@media(max-width:1599px) {
	.reviews {
		padding-right: 100px
	}

	.reviews__list {
		padding-left: 0
	}
}

@media(max-width:1199px) {
	.reviews {
		padding-right: 50px
	}

	.reviews__item {
		flex-wrap: wrap;
		row-gap: 20px;
		padding: 10px 0 20px 0
	}

	.reviews__name {
		font-size: 18px;
		line-height: 25px
	}

	.reviews__subtitle {
		font-size: 18px;
		line-height: 22px
	}
}

@media(max-width:991px) {
	.reviews {
		padding-right: 30px
	}

	.reviews__list {
		margin-top: 30px
	}
}

@media(max-width:767px) {
	.reviews {
		padding-right: 20px
	}
}

@media(max-width:575px) {
	.reviews {
		padding-right: 10px
	}

	.reviews__flex {
		flex-wrap: wrap;
		row-gap: 20px
	}
}

@media(max-width:991px) {
	main {
		margin-top: 90px;

	}
}